import React, { useEffect, useState } from 'react';
import { Flex, ListTable, Typo, Avatar, IconAsButton } from '../../../ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { toggleCourseDraft } from '../../course/courseSlice';

const EditableCoursesList = ({courses}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editableCourses, setEditableCourses] = useState([]);

  useEffect(() => {
    const filteredCourses = courses.map((course)=>{
      if(course.canEdit) return course
      else return null;
    }).filter(Boolean);

    setEditableCourses(filteredCourses)

  },[courses])

  const navigateToCourseSettings = (item)=>
    navigate(`/courses/${item.id}/settings`);
  
  const navigateToCourse = (item)=>
    navigate(`/view/course/${item.id}`)
  
  const navigateToEditCourse = (item)=>
    navigate(`/course/${item.ownerSlug}/${item.slug}`, { state: item });
  
  const handleToggleCourseDraft = (item) => {
console.log('Toggle', item)
    dispatch(toggleCourseDraft({ id: item.id }));
  }
  

  const [tableInfo, setTableInfo] = useState([
    {
      label: t('globals.name'), hasSorting: { sortedMethod: 'string', propVal: 'name' }, data: (item) => {
        return <Flex gap='10'>
          <Avatar url={item.coverImage.url} size='small'>{item.name.substr(0, 1)}</Avatar>
          <div>
            <Typo.Text>{item.name}</Typo.Text>
            <Typo.SmallText>{item.ownerName}</Typo.SmallText>
            <Typo.SmallText>{`course id: ${item.id}`}</Typo.SmallText>
          </div>
        </Flex>
      }
    },
    { label: 'Status', data: (item) => !!item.draft
        ? <IconAsButton tooltipLabel={t('globals.draft')} iconName='InConstruction' clickHandle={(e) => handleToggleCourseDraft(item)}/>
        : <IconAsButton  tooltipLabel={t('globals.ready')} iconName='Published' clickHandle={(e) => handleToggleCourseDraft(item)}/>
    },
    { label: 'Edit content', data: (item) => <IconAsButton tooltipLabel='edit course' iconName='CreateEdit' clickHandle={(e) => navigateToEditCourse(item)} /> },
    { label: 'Edit settings', data: (item) => <IconAsButton tooltipLabel='edit settings' iconName='Settings' clickHandle={(e) => navigateToCourseSettings(item)} /> },
    { label: 'View course', data: (item) => <IconAsButton tooltipLabel='view course' iconName='Preview' clickHandle={(e) => navigateToCourse(item)} /> },
  ]);

  if(!editableCourses.length) return null;

  return (<Flex width='100%' direction='column' align='flex-start' gap='20'>
      <Typo.ContentSmallHeader $textAlign='left'>Your courses</Typo.ContentSmallHeader>
      <ListTable
        tableInfo={tableInfo}
        data={editableCourses}
        // onRowClick={openCourse}
        tableName={'editable-courses'}
        tableSetter={setTableInfo}
        defaultRowPerPage={5}
      />
    </Flex>
  );
};

export default EditableCoursesList;