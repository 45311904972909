import React from 'react';
import styled from 'styled-components';
import { Flex, Typo,  theme, Symbols  } from '../../../ui';

const InsightsCard = styled(Flex)`
  background-color:${theme.colors.PRIMARY_LIGHT}; 
  padding: 22px 38px;
  border-radius: 10px;
`

const SymbolWrapper = styled.div`
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StatsCard = ({label, value, unit, symbolName}) => {

  const SymbolComponent = Symbols[symbolName];
  return (
    <InsightsCard >
      <Flex direction='column' align='flex-start' gap='10'>
        <Typo.TextTitle $textAlign='left'>{label} </Typo.TextTitle>
        <Flex gap='40' justify='space-between' width='100%' align='flex-end'>
          <Flex align='baseline'>
            <Typo.SuperHero $textAlign='left'>{value}</Typo.SuperHero>
            <Typo.TextTitle>{unit}</Typo.TextTitle>
          </Flex>
          <SymbolWrapper>
            <SymbolComponent size='gigantic' color={theme.colors.NEUTRAL_PRIMARY} />
          </SymbolWrapper>
        </Flex>
      </Flex>
    </InsightsCard>
  );
};

export default StatsCard;
