import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack'

import { CONTENT_MINI } from '../../../../utils/media';
import { TOAST_ERROR } from '../../../../utils/constants';
import { Typo, Section, Flex, CoverMedia } from '../../../../ui'
import { useWebshopName } from '../../../../hooks/readFromUrls';

import { addItemToCart } from '../../cartSlice';
import { selectCourseById } from '../../coursesSlice';
import { selectUserTheme } from '../../globalSlice';

import CTABanner from './CTABanner';
import SuggestedCourse from './SuggestedCourse';
import SpotlightCourses from './SpotlightCourses';

import InfoIconSection from '../../components/InfoIconSection';
import InfoPointsSection from '../../components/InfoPointsSection';
import ListCourses from '../../../../components/ListCourses';
import { fetchStoreCategories, selectCategoriesInUse } from '../../../categories/categoriesSlice';
import CourseListFilter from './CourseListFilter';

const coursesRef = React.createRef();
const aboutRef = React.createRef();
const contentRef = React.createRef();

const ShopFrontLayout = ({layoutStructure, courses, cartItems, sectionTitles, preview,
  introduction, infoPoints, infoIcons, coverMedia, suggestedCourse, highlightedCourses, ctaBanner, showCategories, activeModules
}) => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const webshop = useWebshopName();

  const categoriesToFilter = useSelector(selectCategoriesInUse);
  const [selectedFilter, setSelectedFilter] = useState([])

  useEffect(()=>{
    dispatch(fetchStoreCategories(webshop))
  }, [dispatch, webshop])

  const suggestedCourseInfo = useSelector(state =>
    suggestedCourse && !Object.hasOwn(suggestedCourse, 'id')
      ?  selectCourseById(state, suggestedCourse) : suggestedCourse)

  const userTheme = useSelector(selectUserTheme);


  const scrollTo = useCallback((refTarget) => {

    if (!refTarget.current) return null;

    refTarget.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });

    // clean hash
    let noHashURL = window.location.href.replace(/#.*$/, '');
    window.history.replaceState('', document.title, noHashURL);
  }, []);

  useEffect(() => {
    if (hash) {
      if (hash === '#courses') scrollTo(coursesRef);
      else if (hash === '#content') scrollTo(contentRef);
      else if (hash === '#about') scrollTo(aboutRef);
    }
  }, [hash, scrollTo]);

  const addItem = useCallback((item) => {
    if (cartItems.find(el => el === item.id)) enqueueSnackbar(t('webshop.alreadyInCart'), { variant: TOAST_ERROR })
    else if (!!cartItems.length) {
      enqueueSnackbar(t('webshop.canOnlyBuyOne'), { variant: TOAST_ERROR })
    } else {
      dispatch(addItemToCart(item));
      navigate('checkout')
    }
  }, [dispatch, cartItems, t, navigate]);

  const goToCourses = useCallback(() => {
    navigate(`/store/${webshop}/#courses`);
  }, [navigate, webshop])

  if (!layoutStructure) return null

  const showInfoIcons = (!activeModules || activeModules?.infoIcons) && !!infoIcons?.length
  const shouldShowCategories = (!activeModules || activeModules?.showCategories) && !!showCategories

  return (
    <>
      <CoverMedia
        videoPreview={coverMedia?.video?.url}
        thumbnailImage={coverMedia?.image?.url}
        thumbnailId={coverMedia?.image?.id}
        themeColor={userTheme.color}
        emptyCoverMedia={!!activeModules && !activeModules?.coverMedia}
      />
      <CTABanner
        themeColor={userTheme.color}
        goToAllCourses={goToCourses}
        info={
          ctaBanner?.hasOwnProperty('title') && !!ctaBanner?.title?.length ? ctaBanner
          : {title:t('webshop.home.ctaBanner.title'), description:t('webshop.home.ctaBanner.description')}}
      />

      {showInfoIcons && <Section noMarginBottom={true} >
        <InfoIconSection items={infoIcons}/>
      </Section>}

      {(!activeModules || !!activeModules?.introduction) && !!Object.keys(introduction).length &&
        <Section size={CONTENT_MINI} >
          <Flex gap='24' direction='column'>
            <Typo.PageHeader innerRef={aboutRef} >{introduction?.title}</Typo.PageHeader>
            <Typo.Text>{introduction?.text}</Typo.Text>
          </Flex>
        </Section>
      }
      {(!activeModules || !!activeModules?.highlightedCourses) && !!highlightedCourses.length &&
        <SpotlightCourses
          courses={highlightedCourses}
          title={sectionTitles?.occasionCourses || t('webshop.home.occasionCourses')}
        />
      }

      {(!activeModules || !!activeModules?.infoPoints) &&
        <InfoPointsSection
          items={infoPoints}
          refContent={contentRef}
          title={sectionTitles?.infoPointsTitle || t('webshop.home.infoPointsTitle')}
        />
      }

      {(!activeModules || !!activeModules?.suggestedCourse) && !!suggestedCourseInfo &&
        <SuggestedCourse
          suggestedCourse={suggestedCourseInfo}
          addToCart={addItem}
          title={sectionTitles?.popularCourse || t('webshop.home.popularCourse')}
        />
      }

      {!!courses?.length || preview ? 
        <Section title={sectionTitles?.coursesTitle || t('webshop.home.coursesTitle')} innerRef={coursesRef} >
          <Flex direction='column' gap='15' width='100%'>
            { shouldShowCategories &&  <CourseListFilter
              options={categoriesToFilter} 
              handleFilter={setSelectedFilter} 
              selected={selectedFilter} 
            /> }
            <ListCourses
              showProgress={false}
              showButtons={true}
              items={courses}
              addItem={addItem}
              categories={shouldShowCategories && selectedFilter}
            />
          </Flex>
        </Section> : null}
    </>
  );
};

export default ShopFrontLayout;
