import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Typo, Button } from '../../../ui';
import CoursesListCarousel from './CoursesListCarousel';

const DraftCoursesList = ({courses, openModal}) => {
  const { t } = useTranslation();
  
  const [draftCourses, setDraftCourses] = useState([])

  useEffect(() => {
    
    const filteredCourses = courses.map((course)=>{
      if(course.canEdit && course.draft) return course;
      else return null; 
      
    }).filter(Boolean);

    setDraftCourses(filteredCourses)

  },[courses])

  return (<Flex width='100%' direction='column' align='flex-start' gap='20'>
      <Typo.ContentSmallHeader $textAlign='left'>Draft courses</Typo.ContentSmallHeader>
      {draftCourses.length ? <>
          <Button icon='Edit' onClick={openModal} >{t('course.createCourse')}</Button>
          <CoursesListCarousel courses={draftCourses} />
        </> 
        : <>
          <Typo.Text>You have finished editing your courses, maybe time to create a new one?</Typo.Text>
          <Button icon='Edit' onClick={openModal} >{t('course.createCourse')}</Button>
        </>
      }
    </Flex>
  );
};

export default DraftCoursesList;