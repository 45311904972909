import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Typo, Icon, Button, SubPage } from '../../../ui';
import { selectSettings, updateSettings } from '../../settings/settingsSlice';
import { CONTENT_SMALL } from '../../../utils/media';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { NOTIFICATIONS } from '../../../utils/constants';

const ProfileNotifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const settings = useSelector(selectSettings)
  const [checked, setChecked] = useState();

  useEffect(() => {
    const newChecked = [];
    for (const property in settings.notifications) {
      if (settings.notifications[property] === 1) newChecked.push(property);
    }
    setChecked(newChecked);
  }, [settings])

  const handleToggle = useCallback((value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);

    setChecked(newChecked);
  }, [checked]);

  const ToggleEmailNotifications = useCallback((event) => {
    event.stopPropagation();
    const settingsData = {
      sendBulletin: !settings.sendBulletin
    }

    dispatch(updateSettings({ settingsData }))
  }, [dispatch, settings]);

  const saveNotifications = useCallback(() => {
    const notificationsTmp = { ...settings.notifications };

    for (let key in notificationsTmp) {
      notificationsTmp[key] = false;
    }
    checked.map(id => {
      return notificationsTmp[id] = 1;
    });
    const settingsData = {
      notifications: notificationsTmp
    }

    dispatch(updateSettings({ settingsData }))
  }, [dispatch, checked, settings])

  return (
    <SubPage size={CONTENT_SMALL} align='flex-start' gap='20' >
      <Typo.ContentSmallHeader $textAlign='left' >{t('profile.manageNotifications')}</Typo.ContentSmallHeader>
      <Typo.MediumText>{t('profile.manageNotifications_disclaimer')}</Typo.MediumText>
      <div>
        <Accordion>
          <AccordionSummary >
            <Flex gap='20' width='100%' justify='space-between'>
              <Flex gap='10' direction='column' align='flex-start'>
                <Typo.TextTitle>{t('profile.emailNotifications')}</Typo.TextTitle>
                <Typo.MediumText>{t('profile.emailNotifications_description')}</Typo.MediumText>
              </Flex>
              <Button onClick={ToggleEmailNotifications}>{settings.sendBulletin ? t('profile.turnOffEmailReminders') : t('profile.turnOnEmailReminders')}</Button>
            </Flex>
          </AccordionSummary>
          <AccordionDetails>
            <Typo.MediumText>{t('profile.emailNotifications_details')}</Typo.MediumText>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<Icon.ExpandMore />}>
            <Flex gap='10' direction='column' align='flex-start' >
              <Typo.TextTitle>{t('profile.inAppNotifications')}</Typo.TextTitle>
              <Typo.MediumText>{t('profile.inAppNotifications_description')}</Typo.MediumText>
            </Flex>
          </AccordionSummary>
          <AccordionDetails>
            <List sx={{ width: '100%', maxWidth: 360 }}>
              {settings.notifications && Object.keys(settings.notifications).map((id) => {
                const labelId = `checkbox-list-label-${id}`;
                const translationKey = NOTIFICATIONS[id]?.t_key
                const text = `profile.notification_types.${translationKey}`
                if (!translationKey) return null;
                return (
                  <ListItem
                    key={id}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={handleToggle(id)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked?.indexOf(id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <Typo.Text>{t(text)}</Typo.Text>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <Flex justify='flex-end'>
              <Button onClick={saveNotifications}>{t('globals.save')}</Button>
            </Flex>
          </AccordionDetails>
        </Accordion>
      </div>
    </SubPage >
  );
};

export default ProfileNotifications;
