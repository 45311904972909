import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex, Typo, Avatar } from '../../../ui';
import { selectTaskById } from '../../tasks/tasksSlice';
import AnswerStatusIcon from './AnswerStatusIcon';
import TaskModal from './TaskModal';
import ContentWrapper from './ContentWrapper';

const TaskResults = ({ taskId, onClose, uncorrectedOnly }) => {
  const { t } = useTranslation();
  const taskInfo = useSelector((state) => selectTaskById(state, taskId));
  let count = 0

  return (
    <TaskModal
      open={!!taskInfo}
      onClose={onClose}
      title={
        <Flex gap='10' direction='column' align='flex-start' >
          <Typo.TextTitle>{taskInfo?.question}</Typo.TextTitle>
        </Flex>
      }
    >
      <>
        {!!taskInfo && !taskInfo?.users?.length ?
          <Typo.ContentSmallHeader>{t('tasks.noUsersResults')}</Typo.ContentSmallHeader> :
          taskInfo?.users?.map((user) => {
            if (!uncorrectedOnly || (uncorrectedOnly && user.lastAnswerGrade === undefined)) {
              count += 1
              return <ContentWrapper
                key={user.id + 'accordion'}
                item={user}
                userName={user.name}
                courseId={taskInfo?.courseId}
                taskType={taskInfo.type}
              >
                <Flex gap='30' width='100%'>
                  <Avatar size='small' name={user.name} url={user.avatar} hasBadge>
                    <AnswerStatusIcon grade={user.lastAnswerGrade} taskType={taskInfo.type} />
                  </Avatar>
                  <Flex direction='column' align='flex-start'>
                    <Typo.TextTitle>{user.name}</Typo.TextTitle>
                    <Typo.Info>{t(user.lastUpdate.type === 'response' ? 'tasks.responseSubmitted' : 'tasks.feedbackGiven')}: {user.lastUpdate.date}</Typo.Info>
                  </Flex>
                </Flex>
              </ContentWrapper>
            } else return null
          })}
        {count === 0 && !!taskInfo?.users?.length && <Typo.ContentSmallHeader>{t('tasks.emptyResults')}</Typo.ContentSmallHeader>}
      </>
    </TaskModal>
  );
};

export default TaskResults;
