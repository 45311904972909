import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { postApi } from '../../../../utils/urls';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/constants';
import httpClient from '../../../../services/httpClient';
import { enqueueSnackbar } from 'notistack';
import i18next from "i18next";

// ----------------- Thunks -----------------------------

export const sendMessage = createAsyncThunk('post/sendMessage', async ({ data }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      parentId: data.parentId,
      body: data.body
    },
    method: 'create'
  };

  const res = await httpClient.post(postApi(), body, { getState, dispatch, rejectWithValue });
  const message = res.isError ? res.errMsg : i18next.t('coursePage.discussion.sent')
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const editMessage = createAsyncThunk('post/editMessage', async ({ id, content }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      id,
      body: content
    },
    method: 'update'
  };

  const res = await httpClient.post(postApi(), body, { getState, dispatch, rejectWithValue });

  const message = res.isError ? res.errMsg : i18next.t('coursePage.discussion.edited')
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const deleteMessage = createAsyncThunk('post/deleteMessage', async ({ id }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      id,
    },
    method: 'delete'
  };

  const res = await httpClient.post(postApi(), body, { getState, dispatch, rejectWithValue });

  const message = res.isError ? res.errMsg : i18next.t('coursePage.discussion.deleted')
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const toggleFollow = createAsyncThunk('post/follow', async ({ postId, method }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      postId,
    },
    method: method
  };

  const res = await httpClient.post(postApi(), body, { getState, dispatch, rejectWithValue });

  const message = res.isError ? res.errMsg : method === 'follow' ? i18next.t('coursePage.discussion.follow') : i18next.t('coursePage.discussion.unfollow')
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

// ----------------- Reducers ---------------------------

const initialState = {
  data: {}
};

const discussionModuleSlice = createSlice({
  name: 'discussionModule',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(sendMessage.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(editMessage.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(editMessage.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(editMessage.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(deleteMessage.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(deleteMessage.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(deleteMessage.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
      })
  }
})

export default discussionModuleSlice.reducer

// ----------------- Selectors --------------------------
