import { createSlice } from '@reduxjs/toolkit'


// ----------------- Thunks -----------------------------


// ----------------- Reducers ---------------------------

const initialState = {
  tocMenuIsClosed: false,
};

const courseViewSlice = createSlice({
  name: 'viewCourse',
  initialState,
  reducers: {
    toggleTocMenuIsClosed: (state, action) => {
        state.tocMenuIsClosed = !state.tocMenuIsClosed;
    },
  },
})

export default courseViewSlice.reducer;

export const {toggleTocMenuIsClosed} = courseViewSlice.actions;

// ----------------- Selectors --------------------------
export const selectTocMenuIsClosed = state => state.viewCourse.tocMenuIsClosed;
