import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, ListItem } from '@mui/material';
import { IconAsButton, Typo, Button, Flex, theme } from '../../../ui';
import styled from 'styled-components';
import { saveCourseNotesToDocx } from '../../../utils/docxNotesGenerator';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    flex-direction: column;
    align-items: start;
    border-bottom: 1px solid ${theme.colors.NEUTRAL_20};
    gap: 10px;
    padding:16px;
    &:last-child {
      border-bottom: none;
    }
  }
`;

const SectionWrapper = styled(Flex)`
  width: 100%;
`

const SaveButtonWrapper = styled.div`
  margin-top: 20px;
`;

const NotesOverview = ({ notes = [], course, features }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToPage = useCallback((pageId) => {
    if (features.newCourseView) {
      navigate(`/view/course/${course.id}/${pageId}`)
    } else {
      navigate(`/course/${course.ownerSlug}/${course.courseSlug}/${pageId}`);
    }
  }, [navigate, course.id, features.newCourseView, course.courseSlug, course.ownerSlug]);

  const notesList = useMemo(() => {
    return notes.flatMap((section) =>
      (section.pages || []).flatMap((page) => (
        <StyledListItem>
          <SectionWrapper justify={'space-between'}>
            <Typo.TextTitle>
              {section.name} - {page.name}
            </Typo.TextTitle>
            <IconAsButton
              iconName='Send'
              tooltipLabel={t('notes.navigateNote')}
              size='small'
              clickHandle={() => goToPage(page.id)}
            />
          </SectionWrapper>
          {(page.notes || []).map((note) => (
            <div key={note.id || `${page.id}-${note.ts}`}>
              <Typo.Text>{note.body}</Typo.Text>
              <Typo.Info>{format(new Date(note.ts), 'dd/MM/yyyy')}</Typo.Info>
            </div>
          ))}
        </StyledListItem>
      ))
    );
  }, [notes, goToPage, t]);

  return (
    <>
      <List>{notesList}</List>
      <SaveButtonWrapper>
        <Button icon={'Save'} onClick={() => saveCourseNotesToDocx(course)}>{t('globals.save')}</Button>
      </SaveButtonWrapper>
    </>
  );
};

export default NotesOverview;
