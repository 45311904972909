import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon, Typo } from '../../../ui';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  svg {
    margin-right: 10px;
  }
`;

const Teacher = ({ user }) => {
  const { t } = useTranslation();

  return (
    !user ? <div>{t('globals.loading')}</div> :
      <>
        <InfoWrapper>
          <InfoRow>
            <Icon.Email />
            <Typo.Info>{user.email}</Typo.Info>
          </InfoRow>
          <InfoRow>
            <Icon.Time />
            <Typo.Info>{t('users.lastActive')}: {user.lastActive}</Typo.Info>
          </InfoRow>
        </InfoWrapper>
      </>
  );
};

export default Teacher;
