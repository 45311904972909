import React from 'react';
import { Icon, theme } from '../../../ui';

const AnswerStatusIcon = ({ grade }) => {
  const CorrectResponse = Icon['Valid']
  const IncorrectResponse = Icon['Wrong']
  const UnreadResponse = Icon['AnswerUnread']

  return (
    <>
      {grade === 1 ?
        <CorrectResponse size='small' color='green' /> : grade === 0 ?
          <IncorrectResponse size='small' color='red' /> : <UnreadResponse size='small' color={theme.colors.NEUTRAL_PRIMARY} />
      }
    </>
  );
};

export default AnswerStatusIcon;
