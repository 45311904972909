import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Modal, Textinput, Typo } from '../../../../ui';

const EditSectionTitles = ({ open, info, onClose, context, addToPreview, isPackage }) => {

  const { t } = useTranslation();

  const [sectionTitlesInfo, setSectionTitlesInfo] = useState({});

  const titles = {
  course:{
    coursesIncluded: t('webshop.course.coursesIncluded'),
    infoPointsTitle: t('webshop.home.editTitles.informationPointsIcons'),
    courseContentTitle: t('webshop.course.courseContentTitle'),
    relatedCourses: t('webshop.course.coursesList'),
  },
  home:{
    occasionCourses:t('webshop.build.spotlightedCoursesTitle'),
    infoPointsTitle: t('webshop.home.editTitles.infoPointsTitle'),
    popularCourse: t('webshop.build.suggestedCourseTitle'),
    coursesTitle: t('webshop.course.coursesList'),
  }}

  useEffect(() => {
    if (info) setSectionTitlesInfo(info);
  }, [info]);

  const handleSectionTitle = (e, prop)=>{
    setSectionTitlesInfo({...sectionTitlesInfo, [prop]:e.currentTarget.value})
  }

  const onAccept = useCallback(() => {
    addToPreview(sectionTitlesInfo);
  }, [sectionTitlesInfo, addToPreview]);

  return (
    <Modal
      open={open}
      showActions={true}
      onClose={onClose}
      onCancel={onClose}
      onAccept={onAccept}
      acceptLabel={t('webshop.addPreview')}
      titleChildren={<>
        <Typo.ContentSmallHeader>{t('webshop.editStoreTitlesTitle')}</Typo.ContentSmallHeader>
        <Typo.Text>{t('webshop.editStoreTitlesDesc')}</Typo.Text>
      </>
      }
    >
      <Flex direction='column' width='80%' align='flex-start' gap='20'>
      {Object.keys(titles[context]).map((key)=>
        isPackage && key === 'coursesIncluded' ? null :
        <Flex direction='column' width='100%' key={key}>
          <Typo.Text>{titles[context][key]}</Typo.Text>
          <Textinput
            label={`${t('webshop.defaultText')}: ${t(`webshop.${context}.${key}`)}`}
            propName={`section-title-${key}`}
            defaultValue={sectionTitlesInfo[key] || ''}
            onBlurValue={(e) => handleSectionTitle(e, key)}
          />
        </Flex>
      )}
      </Flex>
    </Modal>
  );
};

export default EditSectionTitles;

