import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components'
import { Typo, Loading, Flex, Dropdown, Icon, Button, theme, IconAsButton } from '../../../ui';
import { useDispatch } from 'react-redux';
import { fileUpload, imageUpload } from '../../fileUploader/fileSlice';
import MediaPicker from '../MediaPicker';
import { useTranslation } from 'react-i18next';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';

const FileLoadingWrapper = styled.div`
  width:${({$smallIcon}) => $smallIcon ? '30px': '100%'};
  display:flex;
  justify-content: center;
  flex-direction: column;
  padding: 4rem;
`

export const UPLOADER_TYPE = { VIDEO: 'video', IMAGE: 'image', FILE: 'file' }

const FileUploader = ({
  saveAction,
  uploaderType = UPLOADER_TYPE.FILE,
  id,
  type,
  accept,
  variant,
  bgcolor,
  fileType,
  label,
  iconName,
  noMediaLibrary,
  smallIcon,
  isBlobToReturn = false,
  callbackIsloading=false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [file, setFile] = useState(null);
  const [fileAsBlob, setFileAsBlob] = useState(null);

  const [loading, setLoading] = useState(false);
  // const file = useSelector(selectFile);
  // const fileStatus = useSelector(selectFileStatus);
  const [libraryModal, setLibraryModal] = useState(false);

  const handleLibraryModal = useCallback((e) => {
    e.stopPropagation();
    setLibraryModal(!libraryModal);
  }, [libraryModal]);

  const chooseFile = () => {
    document.getElementById(id).value = null;
    document.getElementById(id).click();
  };

  useEffect(() => {
    if (file) {
      const fileData = isBlobToReturn ? fileAsBlob : { id: file.id, url: file.url, name: file.name }
      saveAction(fileData);
      setLoading(false);
      callbackIsloading && callbackIsloading(false)
      dispatch({ type: 'clearFile' });
    }
  }, [file])// eslint-disable-line react-hooks/exhaustive-deps

  //TODO: figure out why we need this stuff
  // useEffect(() => {
  //   function onCompDestroy() {
  //     saveAction(null);
  //     dispatch({ type: 'clearFile' });
  //   }
  //   onCompDestroy();
  // }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectFile = useCallback(e => {
    const selectedFile = e.target.files[0];
    setFileAsBlob(selectedFile);
    setLoading(true);
    callbackIsloading && callbackIsloading(true);

    switch (uploaderType) {
      case UPLOADER_TYPE.IMAGE:
        dispatch(imageUpload({ file: selectedFile, mockedType: type })).then(action => {
          setFile(action.payload);
        })
        break;
      case UPLOADER_TYPE.VIDEO:
        dispatch(fileUpload(selectedFile)).then(action => {
          //TODO: setFile according video response
          console.log('VIDEO', action)
        });
        break;
      default:
        dispatch(fileUpload(selectedFile)).then(action => {
          setFile(action.payload[0]?.file);
        });
        break;
    }
  }, [dispatch, type]); // eslint-disable-line react-hooks/exhaustive-deps

  const LoadingWrapper = ({$smallIcon}) =>
    <FileLoadingWrapper $smallIcon={$smallIcon}>
      <Loading />
    </FileLoadingWrapper>;

  const EditFile = ({loading}) =>
    <Flex align='flex-end' direction='column'>
      <input accept={accept} type='file' style={{ display: 'none' }} id={id} onChange={onSelectFile} />

      {!noMediaLibrary ? <Dropdown
        label={label || t('globals.attachFile')}
        iconName={iconName || 'Upload'}
        variant={variant || 'customized'}
        bgcolor={bgcolor || theme.colors.COURSIO_NEW_EXTRA}
      >
        <ListItem  disablePadding divider>
          <ListItemButton htmlFor={id} onClick={chooseFile} dense>
            <ListItemIcon><Icon.Devices size='small' /></ListItemIcon>
            <Typo.Text>{t('media.uploadFromComp')}</Typo.Text>
          </ListItemButton>
        </ListItem>
        <ListItem  disablePadding >
          <ListItemButton onClick={handleLibraryModal} dense>
            <ListItemIcon><Icon.Media size='small'/></ListItemIcon>
            <Typo.Text>{t('media.uploadFromMedia')}</Typo.Text>
          </ListItemButton>
        </ListItem>
      </Dropdown>
      : smallIcon ?
        <IconAsButton loading={loading} size='x-small' tooltipLabel='attach a file' iconName={iconName || 'AttachFile'} clickHandle={chooseFile} />
        : <Button
            $variant={variant || 'customized'}
            $bgcolor={bgcolor  || theme.colors.COURSIO_NEW_EXTRA}
            icon={iconName || 'Upload'}
            htmlFor={id}
            onClick={chooseFile} >{label || t('globals.attachFile')}</Button>
      }
    </Flex>

  return (
    loading && !smallIcon ? <LoadingWrapper smallIcon={smallIcon} />
      : <>
          <EditFile loading={loading}/>
          <MediaPicker
            saveAction={saveAction}
            mediaType={fileType || 'all'}
            isOpen={libraryModal}
            closeModal={handleLibraryModal}
          />
        </>
  )
};

export default FileUploader;
