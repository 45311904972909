import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Typo, PersonalMessage, Figure, theme } from '../../../ui';
import { selectUserName } from '../../auth/authSlice';
import { selectSettings } from '../../settings/settingsSlice';

const WelcomeMessage = () => {

  const userName = useSelector(selectUserName);
  const settings = useSelector(selectSettings);

  return (<Flex width='100%' direction='row' justify='space-between' gap='20' >
     <Flex direction='column' align='flex-start' gap='20'>
      <Typo.ContentSmallHeader $textAlign='left'>{`Hi ${userName}!`}</Typo.ContentSmallHeader>
      <PersonalMessage>
        <Typo.Text $textAlign='left'>Congrats - you are doing so good!</Typo.Text>
        <Typo.Text $textAlign='left'>Keep up the good spirit!</Typo.Text>
      </PersonalMessage>
     </Flex>

      <Flex width='200px' breakPoint={{ size: 'phablet', value: 'width:150px; align-self:center;' }}>
        {settings.theme.logoUrl && <Figure.Circle $objectfit='contain' shadow='true' background={theme.colors.NEUTRAL_0}>
          <img src={settings.theme.logoUrl} alt={userName} loading='lazy' />
        </Figure.Circle>}
      </Flex>
    </Flex>
  );
};

export default WelcomeMessage;
