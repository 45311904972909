import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Flex, Modal, Typo } from '../../../ui';
import VideoPlayer from '../../../ui/MediaPlayer/VideoPlayer';

const BoxContainer = styled.div`
  background:${(props) => `linear-gradient(0deg, ${props.$bgColor1} 0%, ${props.$bgColor2} 80%, ${props.$bgColor2} 100%)` };
  min-height:400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 40px;
  max-width:600px;
  align-items:center;
`;

const CreateYourFirstCourse = ({openCreateCourseModal}) => {
  const { t } = useTranslation();

  const [tourIsOpen, setTourIsOpen] = useState(false)

  const openTour = ()=>{
    setTourIsOpen(true)
  }

  const closeTour = () => {
    setTourIsOpen(false)
  }

  return (
    <Flex direction='column' gap='40'>
      <BoxContainer $bgColor1='#E3DAFF' $bgColor2='#0E6C80'>
        <Typo.PageHeader $themeColor='#0E6C80' >Start creating your first course</Typo.PageHeader> 
        <div><Button icon='Edit' onClick={openCreateCourseModal}>{t('course.createCourse')}</Button> </div>
      </BoxContainer>
      <BoxContainer $bgColor2='rgba(255, 210, 218, 1)' $bgColor1='rgba(255, 210, 218, 0.05)'>
        <Typo.PageHeader $themeColor='rgba(255, 210, 218, 1)'>Learn how to create amazing courses</Typo.PageHeader>
       <div><Button onClick={openTour}>Take the tour</Button></div> 
      </BoxContainer>

      <Modal 
        fullScreen
        open={tourIsOpen}
        onClose={closeTour}
      >
        <VideoPlayer url={'https://vimeo.com/932631394'} width={'100%'} height={'100%'}/>
      </Modal>
    </Flex>
  );
};

export default CreateYourFirstCourse;