import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../../ui';
import { currencyFormatter } from '../../../helpers/formatNumbers';
import { media } from '../../../utils/media';
import StatsCard from './StatsCard';

const FlexContainer = styled(Flex)`
  flex-wrap:wrap;
  gap:20px;
  justify-content:center;

  ${media.lessThan('phablet')} {
    flex-direction: column;
  }
`

const StatisticsCards = ({sales, invitations}) => {
  const { t } = useTranslation();

  const [totalCourses, setTotalCourses] = useState(0);

  useEffect(() => {
    let totalCourses = 0;
    if(sales.salesPerObject){
      sales.salesPerObject.forEach(course => {
        totalCourses += course.salesCount;
      });
    }

    setTotalCourses(totalCourses);

  }, [sales]);

  return (
    <FlexContainer>
      <StatsCard 
        label={t('insights.soldCourses')}
        value={totalCourses}
        symbolName={'Books'}
      />
      <StatsCard 
        label={t('insights.totalSales')}
        value={currencyFormatter(sales?.totalValue, { symbol: '' })}
        unit={sales?.storeCurrency}
        symbolName={'BarChart'}
      />
      <StatsCard 
        label={t('insights.sentInvites')}
        value={invitations.count}
        symbolName={'Invitation'}
      />
    </FlexContainer>
  );
};

export default StatisticsCards;
