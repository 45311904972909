import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Ribbon, theme, Typo } from '../../../../ui';
import { selectCourse, selectCourseToc } from '../../../course/courseSlice';
import { useNewViewCourseId, useNewViewPageId } from '../../../../hooks/readFromUrls';
import isEmpty from 'lodash.isempty';
import TocMenuSection from './TocMenuSection';
import { getLighterThemeColor } from '../../../../ui/theme/colors';
import { setSelectedSection } from '../CoursePage/coursePageSlice';
import { useTranslation } from 'react-i18next';
import { selectFeatures } from '../../../settings/settingsSlice';

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
`;

const StyledWrapper = styled.div``

const HeadWrapper = styled.div`
  background: ${({ $ultralightColor }) => $ultralightColor || theme.colors.NEUTRAL_10};
  margin: 0;
  max-width: 600px;
`;

const CourseName = styled.div`
  min-height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    padding: 5px 5px 5px 15px;
  }
`;

const TocMenuContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const course = useSelector(selectCourse);
  const toc = useSelector(selectCourseToc);
  const activePageId = useNewViewPageId();
  const courseId = useNewViewCourseId();
  const refSection = useRef(null);

  const features = useSelector(selectFeatures)

  // Memoize 'active' state to avoid unnecessary re-renders
  const active = useMemo(() => {
    if (!isEmpty(toc) && activePageId !== undefined) {
      const sectionIndex = toc.findIndex((section) => {
        const index = section.pages.findIndex((page) => page.id.toString() === activePageId) !== -1;
        if(index) dispatch(setSelectedSection(section));
        return index
      }
      );
      if (sectionIndex !== -1) {
        const newActive = Array(toc.length).fill(false);
        newActive[sectionIndex] = true;
        return newActive;
      }
    }
    return [];
  }, [toc, activePageId, dispatch]);

  useEffect(()=>{
    if (refSection.current) {
      refSection.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }, [active, refSection])

  const ultralightColor = getLighterThemeColor(course?.theme?.color, 0.95);

  return (
    <>
      {course && (
        <HeadWrapper $ultralightColor={ultralightColor}>
          {features.newDashboard && <Ribbon word={course.draft ? t('globals.draft') : ''} />}
          {course.coverImage?.url && <StyledImage src={course.coverImage?.url} alt="Course" />}          
          <CourseName>
            <Typo.MediumText fontWeight="600" $textAlign="left">
              {course.name}
            </Typo.MediumText>
            <Typo.SmallText fontWeight="300" $textAlign="left">
              {t('globals.by', { name: course.ownerName })}
            </Typo.SmallText>
          </CourseName>
        </HeadWrapper>
      )}

      <div>
        {toc?.map((section, i) =>{
          if ((section.type === 'quiz' && !course.settings?.quiz?.enabled) || !section.pages.length) {
            return null;
          }

          const props = {
            key:`wrapper${i}`,
            ...(!!active[i] && {ref:refSection})
          }

          const Wrapper = !!active[i] ? StyledWrapper  : Fragment
          return <Wrapper {...props}>
            <TocMenuSection
              course={course}
              themeColor={course?.theme?.color}
              section={section}
              activePageId={activePageId}
              active={!!active[i]}
              key={i}
              courseId={courseId}
            />
         </Wrapper>
        })}
      </div>
    </>
  );
};

export default TocMenuContent;
