import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_SUCCEEDED } from '../../utils/constants';
import { statisticsApi } from '../../utils/urls';
import httpClient from "../../services/httpClient";

//Thunks

export const fetchSales = createAsyncThunk('statistics/fetchSales', async ({ startDate, endDate }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      endDate,
      startDate
    },
    method: 'sales'
  };

  const res = await httpClient.post(statisticsApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

export const fetchAnalytics = createAsyncThunk('statistics/fetchAnalytics', async ({ startDate, endDate }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      dimensions: 'ga:pagePath',
      fetchStore: true,
      metrics: 'ga:sessions, ga:averageSessionDuration',
      sort: '-ga:sessions',
      startDate,
      endDate,
    },
    method: 'queryAnalytics'
  };

  const res = await httpClient.post(statisticsApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
});

export const fetchBrokendownAnalytics = createAsyncThunk('statistics/fetchBrokendownAnalytics', async ({ startDate, endDate, filters, dimensions, metrics, sort, courseId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      dimensions: dimensions,
      courseId,
      metrics: metrics,
      filters: filters && 'ga:pagePath=@' + filters,
      sort: sort,
      startDate,
      endDate,
    },
    method: 'queryAnalytics'
  };

  const res = await httpClient.post(statisticsApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

export const fetchInvitations = createAsyncThunk('statistics/fetchInvitations', async ({ startDate, endDate }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      startDate,
      endDate,
    },
    method: 'invitations'
  };

  const res = await httpClient.post(statisticsApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});


//Reducers
const initialState = {
  status: 'idle',
  sales: {},
  analytics: {},
  invitations: {},
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(fetchSales.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchSales.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.sales = action.payload;
      })
      .addCase(fetchAnalytics.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.analytics = action.payload;
      })
      .addCase(fetchInvitations.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchInvitations.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.invitations = action.payload;
      })

  }
});

export default statisticsSlice.reducer;

//Selectors
export const selectSales = state => state.statistics.sales;
export const selectAnalytics = state => state.statistics.analytics;
export const selectInvitations = state => state.statistics.invitations;
export const selectStatus = state => state.statistics.status;
