import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubMenu, SubMenuWrapper } from '../../ui';

const Settings = () => {

  const { t } = useTranslation();

  const navigationItems = [
    { label: t('settings.branding'), 'value': 'branding' },
    { label: t('settings.store'), 'value': 'store' },
    { label: t('settings.termsConditions'), 'value': 'terms-conditions' },
    { label: t('settings.features'), 'value': 'features' },
    { label: t('settings.tracking'), 'value': 'tracking' },
    { label: t('settings.webinar'), 'value': 'webinar' },
  ]

  return (
    <>
      <SubMenuWrapper>
        <SubMenu navigationItems={navigationItems} pathnameSplitIndex={2} />
      </SubMenuWrapper>
      <Outlet />
    </>
  );
};

export default Settings;
