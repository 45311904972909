import React from 'react';
// import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { TASK_TYPE } from '../../../../utils/constants';
import { ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flex, theme, Typo } from '../../../../ui';
import AnswerStatusIcon from '../../../tasks/components/AnswerStatusIcon';
import DownloadableAttach from '../../../tasks/components/DownloadableAttach';
import { getDarkerThemeColor, getLighterThemeColor } from '../../../../ui/theme/colors';
import SpeechBubble from '../../../../ui/SpeechBubble';

const ListItemStyled = styled(ListItem)`
  flex-direction: column;
  &.MuiListItem-root{
    align-items: flex-start;
    gap: 12px;
    
  }
  padding: 0px 0 30px;
  &:not(:first-of-type){
    padding-top: 25px;
  }
  &:not(:last-of-type){
    border-bottom: 1px solid ${theme.colors.NEUTRAL_20};
  }
`

const IconWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`

const Results = ({taskOptions, result, taskType, themeColor}) => {
  const { t } = useTranslation();
  const resultValues = ![TASK_TYPE.SINGLE, TASK_TYPE.MULTIPLE].includes(taskType) ? result.body.value : result.body.value.map((value)=> taskOptions[value])
  const grade = ![TASK_TYPE.SINGLE, TASK_TYPE.MULTIPLE].includes(taskType) ? result.feedback.grade : result.points

  const answer = {
    id: result.id,
    value: resultValues,
    created: result.created,
    files: result.bodyFiles,
    feedback:{
      created: result.feedback.created,
      text: result.feedback.text,
      files: result.feedbackFiles,
      created_by:result.feedback.created_by
    },
    grade:grade
  }

  // "Access to the endpoint is not allowed for your role. (Error: 65005)." we need the name instead of id then
  // useEffect(()=>{

  //   if(result.feedback.created_by)
  //     dispatch(fetchUserById({ id: result.feedback.created_by })).then(res => {
  //       console.log('FETCH USER', res.payload)
  //       setTeacher(res.payload)
  //     })
  // }, [result.feedback.created_by])

  return (
    <ListItemStyled key={answer.id} disablePadding >
      {answer.created && <Typo.Info>{answer.created}</Typo.Info>}
      <div>
        <Flex>
          {answer.grade !== undefined && <IconWrapper>
            <AnswerStatusIcon grade={answer.grade} />
          </IconWrapper>}
          <Typo.TextTitle>{t('tasks.results.youAnswered')}</Typo.TextTitle>
        </Flex>

        {answer.value.map((item, index) => <Typo.Text key={answer.id + '-reply-' + index}>{item}</Typo.Text>)}
      </div>
      <DownloadableAttach files={answer.files} />

      { answer.grade === undefined && <Typo.MediumText color={getDarkerThemeColor(themeColor) || theme.colors.PRIMARY} fontWeight='500' >{t('tasks.results.noFeedback')}</Typo.MediumText> }
      { answer.feedback?.created && <SpeechBubble themeColor={getLighterThemeColor(themeColor, 0.95)}>
          <Typo.Info>{answer.feedback?.created}</Typo.Info>
          {/* {<Typo.SmallText fontWeight='600'>{teacher?.name}:</Typo.SmallText>} */}
          <Typo.Text>{answer.feedback?.text || t('tasks.results.noComment')}</Typo.Text>
          <DownloadableAttach files={answer.feedback.files} />
        </SpeechBubble>

      }
    </ListItemStyled>
  )
};

export default Results;
