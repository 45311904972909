import React, { useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { restorePassword } from '../authSlice';
import { Textinput, Button, theme, Typo, Flex, Page, PageHeaderLogo, PageContainerVCenter, PageBoxContent } from '../../../ui';
import { useTranslation } from 'react-i18next';
import { TOAST_ERROR } from '../../../utils/constants';
import { enqueueSnackbar } from 'notistack';

const ButtonStyled = styled(Button)`
  align-self:flex-end; 
`

const RestorePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { key } = useParams();
  const passRef = useRef(null);
  const passVerifyRef = useRef(null);

  const handleReset = () => {
    // const urlParts = window.location.pathname.split('/');
    // const key = urlParts[urlParts.length - 1];

    const restorePassData = {
      key,
      password: passRef?.current?.value,
      passwordVerify: passVerifyRef?.current?.value
    };

    if (restorePassData.password !== restorePassData.passwordVerify)
      return enqueueSnackbar(t('auth.passMismatch'), { variant: TOAST_ERROR });

    dispatch(restorePassword(restorePassData)).then(res => {
      if (res.error?.message !== 'Rejected') {
        navigate('/login');
      }
    })
  };

  return (
    <Page backgroundColor={theme.colors.PRIMARY} fullheight={true}>
      <PageHeaderLogo />
      <PageContainerVCenter >
        <PageBoxContent>
          <Typo.ContentSmallHeader $marginBottom='30px'>{t('auth.resetPassword')}</Typo.ContentSmallHeader>
          <Flex direction='column' minWidth='300px'>
            <Textinput
              margin='dense'
              id='pass'
              label={t('auth.newPassword')}
              type='password'
              fullWidth
              inputRef={passRef}
            />
            <Textinput
              margin='dense'
              id='pass2'
              label={t('auth.repeatPassword')}
              type='password'
              fullWidth
              inputRef={passVerifyRef}
            />
            <ButtonStyled onClick={handleReset}>{t('auth.resetPassword')}</ButtonStyled>
          </Flex>
        </PageBoxContent>
      </PageContainerVCenter>
    </Page>
  );


};

export default RestorePassword;
