import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { enqueueSnackbar } from 'notistack';
import httpClient from '../../services/httpClient';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_INFO } from '../../utils/constants';
import { diplomaApi } from '../../utils/urls';
import i18next from 'i18next';

// ----------------- Thunks -----------------------------

export const checkDiplomaInfo = createAsyncThunk('diploma/checkDiplomaInfo', async ({ code }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { code },
    method: 'validateUniqueCode'
  };

  const res = await httpClient.post(diplomaApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
})

export const getDiplomaList = createAsyncThunk('diploma/getDiplomaList', async (_, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {},
    method: 'list'
  };

  const res = await httpClient.post(diplomaApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
})
export const downloadDiploma = createAsyncThunk('diploma/downloadDiploma', async ({ courseId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { courseId },
    method: 'download'
  };

  const res = await httpClient.post(diplomaApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
})

export const emailDiploma = createAsyncThunk('diploma/emailDiploma', async ({ courseId, recipientEmail }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { courseId, recipientEmail },
    method: 'mail'
  };

  const res = await httpClient.post(diplomaApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
})


// ----------------- Reducers ---------------------------
const diplomasAdapter = createEntityAdapter()

const initialState = {
  status: 'idle',
  info: {},
  list: {
    ids: [],
    entities: {}
  }
}

const diplomaSlice = createSlice({
  name: 'diploma',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(emailDiploma.fulfilled, (state, action) => {
        enqueueSnackbar(i18next.t('diploma.emailSent'), { variant: TOAST_INFO })
      })
      .addCase(emailDiploma.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(checkDiplomaInfo.pending, (state, action) => {
        state.status = STATUS_LOADING;
        state.info = {}
      })
      .addCase(downloadDiploma.pending, (state, action) => {
        enqueueSnackbar(i18next.t('diploma.preparingDownload'), { variant: TOAST_INFO })
      })
      .addCase(checkDiplomaInfo.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(checkDiplomaInfo.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.info = action.payload
      })
      .addCase(getDiplomaList.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        diplomasAdapter.setAll(state.list, action.payload)
      })
      .addCase(getDiplomaList.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(getDiplomaList.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
  }
})

// export const {  } = diplomaSlice.actions

export default diplomaSlice.reducer

// ----------------- Selectors --------------------------
export const {
  selectAll: selectDiplomaList,
  selectById: selectDiplomaById,
} = diplomasAdapter.getSelectors((state) => state.diploma.list)

export const selectStatus = state => state.diploma.status;
export const selectDiploma = state => state.diploma.info;

