import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Typo, Button, Flex } from '../../../../ui';
import { selectStoreName } from '../../globalSlice';
import { resetPaymentProcessData, selectPaymentProcessData } from '../../paymentsSlice';
import MessageContainer from './MessageContainer';

const PaymentErrorMessage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storeName = useSelector(selectStoreName);
  const paymentProcessData = useSelector(selectPaymentProcessData);

  const goToStore = useCallback(() => {
    dispatch(resetPaymentProcessData());
    navigate(`/store/${storeName}`);
  }, [navigate, dispatch, storeName]);

  return (
    <MessageContainer >
      <Flex align='center' direction='column'>
        <Typo.TextTitle>{t('webshop.checkout.payment.errorTitle')}</Typo.TextTitle>
        {paymentProcessData?.isError && <Typo.Text>{t('webshop.checkout.payment.errorMessage', { error: paymentProcessData.errMsg })}</Typo.Text>}
      </Flex>
      <Button $variant='primary' onClick={goToStore}>{t('webshop.cart.backToShopping')}</Button>
    </MessageContainer>

  );
};

export default PaymentErrorMessage;

