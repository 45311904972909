import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/sv';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { Page } from '../../ui';
import { selectIsIncarnated, selectMyStore } from '../auth/authSlice';
import {
  fetchAnalytics,
  fetchInvitations,
  fetchSales,
  selectInvitations,
  selectSales
} from '../statistics/statisticsSlice';
import { fetchStore } from '../webshop/globalSlice';
import CourseUsageAnalytics from './components/CourseUsageAnalytics';
import TimePeriodSelector from './components/TimePeriodSelector';
import StatisticsCards from './components/StatisticsCards';
import SalesDataTable from './components/SalesDataTable';

dayjs.extend(quarterOfYear);

const Insights = () => {
  const dispatch = useDispatch()
  const sales = useSelector(selectSales)
  const invitations = useSelector(selectInvitations)
  const isIncarnated = useSelector(selectIsIncarnated);
  const storeName = useSelector(selectMyStore);

  const [startDate, setStartDate] = useState(dayjs().startOf('quarter'));
  const [endDate, setEndDate] = useState(dayjs());
  const [courseModalisOpen, setCourseModalisOpen] = useState();

  useEffect(() => {
    if (endDate && startDate) {
      const dates = { startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] }
      dispatch(fetchSales(dates));
      dispatch(fetchAnalytics({ ...dates, fetchStore: true }));
      dispatch(fetchInvitations(dates));
    }
  }, [dispatch, isIncarnated, startDate, endDate])

  useEffect(() => {
    storeName && dispatch(fetchStore({ store: storeName }));
  }, [dispatch, storeName])

  const openAnalyticDetails = (course) => {
    setCourseModalisOpen(course)
  }

  return (
    <Page gap='60' staticElements={
      <TimePeriodSelector 
        startDate={startDate} 
        setStartDate={setStartDate} 
        endDate={endDate}
        setEndDate={setEndDate}
      /> 
    }>
      <StatisticsCards sales={sales} invitations={invitations} />
      <SalesDataTable sales={sales} invitations={invitations} openAnalyticDetails={openAnalyticDetails} />
      <CourseUsageAnalytics startDate={startDate.toISOString().split('T')[0]} endDate={endDate.toISOString().split('T')[0]} courseToShow={courseModalisOpen} onClose={() => setCourseModalisOpen()} />
    </Page >
  );
};

export default Insights;
