import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { pageApi } from '../../../../utils/urls';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED } from '../../../../utils/constants';
import httpClient from '../../../../services/httpClient';

// ----------------- Thunks -----------------------------

export const fetchPageById = createAsyncThunk('page/fetchPageById', async ({ pageId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      id: pageId,
    },
    method: 'findById'
  };

  const res = await httpClient.post(pageApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

export const markPageAsViewed = createAsyncThunk('page/markPageAsViewed', async ({ pageId, courseId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      courseId,
      pageId,
    },
    method: 'markViewed'
  };

  const res = await httpClient.post(pageApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
});

// ----------------- Reducers ---------------------------

const initialState = {
  data: {},
  activeSection: null,
};

const coursePageSlice = createSlice({
  name: 'coursePage',
  initialState,
  reducers: {
    updateTaskResult: (state, action) => {
      const { moduleId } = action.payload;

      const targetModule = state.data.pageRows
        .flatMap(row => row.pageModules)
        .find(module => module.id === moduleId);

      if (targetModule) {
        targetModule.data.params.taskResults.push(action.payload);
      }
    },
    clearPage(state) {
      state.data = {};
      state.activeSection = null;
      state.status = 'idle';
    },
    setSelectedSection: (state, action)=>{
      state.activeSection = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPageById.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchPageById.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchPageById.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(markPageAsViewed.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
  }
})

export default coursePageSlice.reducer

export const { updateTaskResult, clearPage, setSelectedSection } = coursePageSlice.actions;

// ----------------- Selectors --------------------------
export const selectPage = state => state.coursePage.data;
export const selectPageStatus = state => state.coursePage.status;
export const selectActiveSection = state => state.coursePage.activeSection;

