import React, { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Icon } from '..';
import IframeEmbedder from './IframeEmbedder';
import { baseMediaUrls } from '../../utils/constants';

const PlayerWrapper = styled.div`
  max-width: 100%;
  max-height: ${({ $maxHeight }) => ($maxHeight ? $maxHeight : 'none')};
  position: relative;

  overflow: ${({ $isAudio }) => ($isAudio ? 'visible' : 'hidden')};
  padding-top: ${({ $isAudio }) => ($isAudio ? '0' : '56.25%')};
`;

const ResponsivePlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`

const MediaPlayer = ({ previewContent, posterImage, width, height, isAudio = false, playIconColor, hoverEffectColor, maxHeight }) => {
  // const url= "//coursio.s3-eu-west-1.amazonaws.com/8320075e77fede6a6422f89d1945a48e/b413763961f605bfa05c5d4a12c1237a/7c0db16b3a62e9b754ae6cb4aacf91e53dd3c7ef70b76ee788f439b2fa1c0157.jpg"
  // const vimeoCoursioUrl = `https://vimeo.com/375688422`//`https://vimeo.com/565617079`
  // const vimeoPublicUrl = `https://player.vimeo.com/video/591721025`
  // const wrongVideoUrl = `//coursio.s3-eu-west-1.amazonaws.com/b1be259d8e3577c0d3d06f2379c6dd9c/6eaced2908a59a5e1e9a56345efb5456/76178e82af91ffe607a3231ad22a47e9ee5bd72d6bb2c0117f971f96af24f7d1`
  // const youtubeVideourl = 'https://youtu.be/xFEAOzWLx8Y'

  // previewContent = youtubeVideourl
  const [canPlayMedia, setCanPlayMedia] = useState(true);
  const [isOldStructure, setIsOldStructure] = useState(false);
  const [embedUrl, setEmbedUrl] = useState();

  useEffect(() => {
    try {
      if (typeof JSON.parse(previewContent) === 'object' && previewContent !== null) {
        setIsOldStructure(true);
      }
    } catch (error) {}

    if (isOldStructure) {
      const embedData = JSON.parse(previewContent);

      setEmbedUrl(`${baseMediaUrls[embedData.provider]}${embedData.src}`);
      // setCanPlayMedia(ReactPlayer.canPlay(embedUrl));
    }
    // else {
    //   setCanPlayMedia(ReactPlayer.canPlay(previewContent));
    // }
  }, [previewContent, isOldStructure])

  const handleError = useCallback(err => {
    console.log('Media ERROR', err)
    setCanPlayMedia(false)
  }, [setCanPlayMedia])

  return (
    <PlayerWrapper $maxHeight={maxHeight} $isAudio={isAudio}>
      {canPlayMedia ? (
        <ResponsivePlayer
          url={isOldStructure ? embedUrl : previewContent}
          controls
          config={{ file: { forceAudio: isAudio } }}
          width={'100%'}
          height={isAudio ? '54px' : '100%' }
          playIcon={<Icon.Play size='xxl' $hoverEffectColor={hoverEffectColor} color={playIconColor || 'white'} />}
          light={posterImage ? posterImage : false}
          onError={handleError}
          playing={!!posterImage}
        />
      ) : (
        <IframeEmbedder url={previewContent} />
      )}
    </PlayerWrapper>
  );
};

export default MediaPlayer;
