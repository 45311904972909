import { Tooltip } from '@mui/material';
import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Flex, Icon, Typo, theme } from '../../../ui';
// import { selectFeatures } from '../../settings/settingsSlice';

const Content = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:240px;
  background-color: ${theme.colors.NEUTRAL_LIGHT};
  border-radius:14px;
  
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  :hover{
    background-color: ${theme.colors.NEUTRAL_20};
  }

  div > i { margin-right: 0.5rem; }
`;

export const ItemTypes = {
  All: 'all',
}

const Folder = ({ data, openFolder }) => {
  // const features = useSelector(selectFeatures)
  return (
    <Tooltip title={data.name} >
      <Content onClick={() => openFolder(data)} >
        <Flex>
          <Icon.Folder size='small' color={theme.colors.NEUTRAL_60} />
          <Typo.SmallText>{data.name}</Typo.SmallText>
        </Flex>
      </Content>
    </Tooltip>
  )
}

export default Folder;
