import React from 'react';
import styled from 'styled-components';
import { drawerWidth } from '../../../../utils/constants';
import { theme } from '../../../../ui';
import { media } from '../../../../utils/media';
import TocMenuContent from './TocMenuContent';

const Wrapper = styled.div`
  width:${(props)=> props.$hideMenu ? '46' : drawerWidth}px;
  background-color: white;
  position: fixed;
  z-index: 2;
  top: ${theme.headerSize};
  left: 0;
  padding-top:10px;
  height: calc(100vh - ${theme.headerSize});
  overflow: scroll;
  border-right: 1px solid ${theme.colors.NEUTRAL_20};

  ${media.lessThan('tablet')} {
    display:none;
  }
`

const TocMenu = ({isTocMenuClosed}) => {

  return (
    <Wrapper $hideMenu={isTocMenuClosed}>
      {!isTocMenuClosed && <TocMenuContent />}
    </Wrapper>
  );
};

export default TocMenu;
