import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchStore,
  setStoreName,
  selectDiscountCode,
} from '../webshop/globalSlice';
import { selectAuthState, setIsIncarnated } from '../auth/authSlice';
import MenuSlider from '../../components/MenuSlider';
import IncarnateModal from '../../components/IncarnateModal';
import HeaderContainer from './HeaderContainer';
import FooterContainer from './Footer';
import { fetchUnreadNotifications } from '../../features/notifications/notificationsSlice';
import ScrollToTop from '../../utils/browserScroll';
import SendInvite from '../invites/components/SendInvite';
import { fetchSettings, fetchStoreCurrency } from '../settings/settingsSlice';
import { useModule, useWebshopName } from '../../hooks/readFromUrls';
import LoginModal from '../auth/components/LoginModal';
import { useNoHeaderModules } from '../../hooks/noHeaderModules';
import ManageCookiesBanner from '../manageCookiesBanner';
import WarningMessages from './WarningMessages';
import { Toolbar } from '@mui/material';
import NewFeatures from '../newFeatures';

const ContentWrapp = styled.div`
  height: ${(props) => props.$noHeader ? '100vh' : `calc(100vh - ${props.theme.headerSize})`} ;
  width:100%;
`
const WenshopContent = styled.div`
  min-height: ${(props) => `calc(100vh - ${props.theme.headerSize} - ${props.theme.footerSize})`} ;
  width:100%;
`

const Wrapper = styled.div`
  .MuiToolbar-root{
    height:${(props)=>props.theme.headerSize};
    min-height:${(props)=>props.theme.headerSize};
  }
`

const WrappContainer = ({ children }) => {

  const dispatch = useDispatch();
  const [incarnated, setIncarnated] = useState(false)

  const authState = useSelector(selectAuthState);
  const module = useModule();
  const noHeader = useNoHeaderModules();
  const webshop = useWebshopName()
  const discountCode = useSelector(selectDiscountCode);

  useEffect(() => {
    if (authState.loggedIn) {
      dispatch(fetchStoreCurrency())
      dispatch(fetchUnreadNotifications())
      dispatch(fetchSettings())
    }
  }, [dispatch, authState]);

  useEffect(() => {
    if (module === 'store' || module === 'store_edit') {
      dispatch(setStoreName(webshop))
      dispatch(fetchStore({ store: webshop, discountCode }))
    }
  }, [webshop, discountCode, dispatch, module])

  useEffect(() => {
    const isIncarnated = JSON.parse(localStorage.getItem('user'))?.incarnated
    if (isIncarnated && !incarnated) {
      setIncarnated(true)
      dispatch(setIsIncarnated(true))
    }
  }, [dispatch, incarnated])

  const ContentContainer = useMemo(() => module === 'store' ? WenshopContent : ContentWrapp, [module])
  
  return (
    <Wrapper >
      <MenuSlider />
      <IncarnateModal />
      <SendInvite />
      <LoginModal />
      <WarningMessages show={false && !(noHeader || module === 'store')} />
      <NewFeatures enabled={false} />
      <HeaderContainer noHeader={noHeader} />
      <ScrollToTop />
      {/* <Cart /> */}
      {!noHeader && <Toolbar />}
      <ContentContainer $noHeader={noHeader} >
        {children}
      </ContentContainer>
      {module === 'store' && <FooterContainer />}
      <ManageCookiesBanner />
    </Wrapper>
  );
};

export default WrappContainer;
