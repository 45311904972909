import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { format } from 'date-fns';

// generate a DOCX file for all courses
export const saveAllNotesToDocx = (courses) => {
  if (!courses.length) return;

  const docContent = courses.flatMap((course) => {
    const courseHeader = [
      new Paragraph({
        children: [
          new TextRun({
            text: `Course: ${course.name}`,
            bold: true,
            size: 26,
            underline: true,
          }),
        ],
      }),
      new Paragraph(' '),
    ];

    const courseNotes = course.sections.flatMap((section) => {
      const sectionHeader = [
        new Paragraph({
          children: [
            new TextRun({
              text: `Section: ${section.name}`,
              bold: true,
              size: 24,
              underline: true,
            }),
          ],
        }),
        new Paragraph(' '),
      ];

      const sectionNotes = section.pages.flatMap((page) => {
        const pageHeader = [
          new Paragraph({
            children: [
              new TextRun({
                text: `Page: ${page.name}`,
                bold: true,
                size: 22,
              }),
            ],
          }),
          new Paragraph(' '),
        ];

        const pageNotes = page.notes.map((note) => [
          new Paragraph({
            children: [
              new TextRun({
                text: note.body,
                size: 22,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Date: ${format(new Date(note.ts), 'dd/MM/yyyy')}`,
                italics: true,
                size: 18,
              }),
            ],
          }),
          new Paragraph(' '),
        ]);

        return [...pageHeader, ...pageNotes.flat()];
      });

      return [...sectionHeader, ...sectionNotes.flat()];
    });

    return [...courseHeader, ...courseNotes.flat()];
  });

  const doc = new Document({ sections: [{ children: docContent }] });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `All_Notes.docx`);
  });
};

// generate a DOCX file for a single course
export const saveCourseNotesToDocx = (course) => {
  if (!course || !course.sections) return;

  const docContent = [];

  docContent.push(
    new Paragraph({
      children: [
        new TextRun({
          text: `Course: ${course.name}`,
          bold: true,
          size: 26,
          underline: true,
        }),
      ],
    }),
    new Paragraph(' ')
  );

  course.sections.forEach((section) => {
    docContent.push(
      new Paragraph({
        children: [
          new TextRun({
            text: `Section: ${section.name}`,
            bold: true,
            size: 24,
            underline: true,
          }),
        ],
      }),
      new Paragraph(' ')
    );

    section.pages.forEach((page) => {
      docContent.push(
        new Paragraph({
          children: [
            new TextRun({
              text: `Page: ${page.name}`,
              bold: true,
              size: 22,
            }),
          ],
        }),
        new Paragraph(' ')
      );

      page.notes.forEach((note) => {
        docContent.push(
          new Paragraph({
            children: [
              new TextRun({
                text: note.body,
                size: 22,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Date: ${format(new Date(note.ts), 'dd/MM/yyyy')}`,
                italics: true,
                size: 18,
              }),
            ],
          }),
          new Paragraph(' ')
        );
      });
    });
  });

  const doc = new Document({ sections: [{ children: docContent }] });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `Course_Notes_${course.name}.docx`);
  });
};
