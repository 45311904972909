import React from 'react';

const IframeEmbedder = (data, id = '') => {

  const transformVimeoUrl = (url) => {
    if (!url) return '';

    const match = url.match(/vimeo\.com\/(\d+)/);
    return match ? `https://player.vimeo.com/video/${match[1]}` : url;
  };

  const fixedId = typeof id === 'object' ? '' : id;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const newUrl = transformVimeoUrl(data.url);

  return (
    <iframe
      // className={this.props.className}
      // src={url + id + this.props.urlSuffix}
      src={isMobile ? newUrl + fixedId : data.url + fixedId}
      frameBorder={0}
      scrolling={'no'}
      allowFullScreen
      title='embed'
    />
  )
};

export default IframeEmbedder;
