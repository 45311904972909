import React from 'react';
import Media from '../../../helpers/media';
import styled from 'styled-components';

const SimplerEmbed = styled.div`
  max-width: 100%;
  width:100%;
  position: relative;
  iframe {
    max-height: 450px;
    width: 100% !important;
  }
  & :first-child {
    min-width: 0 !important
  }
`

const css = {
  audio: {
    display: 'block',
    maxWidth: '100%',
    height: 165,
    width: '100%'
  },
  iframeVideoWrapper: {
    width: '100%',
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '450px',
    position: 'relative',
    paddingBottom: '56.25%', /* 16:9 */
    height: 0,
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  }
};

const IframeEmbedder = ({ url, id, urlSuffix = '' }) => {
  const baseUrl = url.startsWith('//') ? `https:${url}` : url;
  const fullUrl = baseUrl + id + urlSuffix;

  return (
    <div style={css.iframeVideoWrapper}>
      <iframe
        style={css.iframe}
        title={'UniqOne'}
        src={fullUrl}
        frameBorder={0}
        allow='autoplay; fullscreen; picture-in-picture'
        allowFullScreen
      />
    </div>
  );
};

const YouTube = ({ id }) => <IframeEmbedder url={Media.baseUrls[1]} id={id} />;
const Vimeo = ({ id }) => <IframeEmbedder url={Media.baseUrls[2]} id={id} />;
const Wistia = ({ id }) => <IframeEmbedder url={Media.baseUrls[5]} id={id} />;

const Docs = ({ data, provider }) => {
  const url = Media.getUrlFromProvider({ provider, id: data });
  return <IframeEmbedder url={url} />
};

const SoundCloud = ({ id, type }) => {
  if (type === 'track') {
    return (
      <IframeEmbedder
        type="audio"
        url={Media.baseUrls[3]}
        id={id}
      />
    );
  } else {
    return <div dangerouslySetInnerHTML={{ __html: id }} />;
  }
};

const Spotify = ({ id, user, type }) => {
  const url = user ? `user:${user}:${type}:${id}` : id;
  return <IframeEmbedder url={Media.baseUrls[4]} id={url} />;
};

const Youku = ({ id }) => (
  <IframeEmbedder
    url={Media.baseUrls[6]}
    id={id}
    allowFullScreen
  />
);

const providers = {
  1: YouTube,
  2: Vimeo,
  3: SoundCloud,
  4: Spotify,
  5: Wistia,
  6: Youku,
  7: Docs
};

const EmbedModule = React.memo(({ data, params }) => {

  if(!data && !params) return null

  const source = !!data?.sources && data?.sources[0]
  const provider = source?.provider || params?.provider

  const Provider = providers[provider];

  return (
    Provider ? <Provider {...(params ? params : { provider, data: source.data })} />
    : provider === 8 && <SimplerEmbed  dangerouslySetInnerHTML={{ __html: source.data }} />
  );
});

export default EmbedModule;
