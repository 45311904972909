import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'

import { Typo, Button, Textinput, Flex, Toggler } from '../../../../ui';
import UcpPeriodSelector from '../../../../components/UcpPeriodSelector';
import { getPeriodByTime } from '../../../../utils/ucpPeriods';
import { useTranslation } from 'react-i18next';
import Buttons from '../../../../ui/Buttons';
import { toggleCourseDraft } from '../../courseSlice';
import { selectFeatures } from '../../../settings/settingsSlice';

const ButtonsWrapper = styled.div`
  margin-top:1rem;
  display: flex;
  width:100%;
  justify-content: flex-end;
  
  button {
    margin-left:0.5rem;
  }
`

const ToggleWrapper = styled.div`
  display:flex;
  max-width:200px;
  margin-bottom:20px;
`

const CourseInfoEdit = ({ course, courseDurations, onSaveInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const courseNameRef = useRef('');
  const courseDescriptionRef = useRef('');
  const ucpPeriod = getPeriodByTime(course.ucpPeriod);
  const [duration, setDuration] = useState({});
  const [isDraft, setIsDraft] = useState(false);
  const features = useSelector(selectFeatures);

  useEffect(() => {
    setIsDraft(course.draft)
    if (course.id) {
      courseNameRef.current.value = course.name
      courseDescriptionRef.current.value = course.description
    }
  }, [course])

  const onSave = useCallback(() => {
    onSaveInfo({
      name: courseNameRef.current.value,
      description: courseDescriptionRef.current.value,
      ucpPeriod: duration?.time ? duration.time : ucpPeriod?.time,
    })
  }, [onSaveInfo, courseDescriptionRef, courseNameRef, duration, ucpPeriod])

  const handleEditingOff = useCallback((e) => {
    courseDescriptionRef.current.value = course.description;
    courseNameRef.current.value = course.name;
    setDuration(ucpPeriod)
  }, [courseDescriptionRef, courseNameRef, course, ucpPeriod])

  const handleIsDraftToggle = useCallback((checked) => {
    setIsDraft(checked)
    dispatch(toggleCourseDraft({ id: course.id }));
  }, [course, dispatch])

  return (
    course.id &&
    <Flex width='100%' direction='column' align='flex-start'>
      {features.newDashboard && <ToggleWrapper><Toggler
        leftText={t('globals.ready')}
        rightText={t('globals.draft')}
        checked={isDraft}
        onClick={handleIsDraftToggle}
      /></ToggleWrapper> }

      <Typo.ContentBigHeader $textAlign='left' $marginBottom='10px'>{t('course.courseInfo')}</Typo.ContentBigHeader>
      <Textinput
        key={`course-name`}
        inputRef={courseNameRef}
        label={t('course.courseName')}
        defaultValue={course.name}
        helperText={t('course.nameWarning')}
      />
      <Textinput
        key={`course-description`}
        inputRef={courseDescriptionRef}
        label={t('course.courseDesc')}
        isMultiline
        defaultValue={course.description}
      />
      <div>
        {ucpPeriod && <UcpPeriodSelector
          duration={duration?.label ? duration : ucpPeriod}
          options={courseDurations}
          setDuration={setDuration}
        />}
      </div>
      <ButtonsWrapper>
        <Buttons.Cancel onClick={handleEditingOff} />
        <Button onClick={onSave}>{t('globals.save')}</Button>
      </ButtonsWrapper>
    </Flex>
  );
};

export default CourseInfoEdit;
