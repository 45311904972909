import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, theme, Typo, PageContainerVCenter, PageHeaderLogo } from '../../ui';

const CoursioError = () => {

  const { t } = useTranslation();

  return (
    <Page backgroundColor={theme.colors.PRIMARY} fullheight={true}>
      <PageHeaderLogo />
      <PageContainerVCenter>
        <Typo.ContentSmallHeader $themeColor={theme.colors.PRIMARY} >{t('genericPages.errorPage.genericMessage')}</Typo.ContentSmallHeader>
      </PageContainerVCenter>
    </Page>
  );
};

export default CoursioError;
