import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxButton from '../../../../ui/CheckboxButton';
import { List, ListItem } from '@mui/material';
import { IconAsButton, Typo } from '../../../../ui';

const EditPackageCourseSequence = ({ checked, onChange, courses, resortItems, sequence }) => {
  const { t } = useTranslation();

  const moveItem = (elementId, direction) => {
    if (!['up', 'down'].includes(direction)) return false;

    const order = [...sequence];
    const index = order.indexOf(elementId);
    order.splice(index, 1);

    if (direction === 'down') order.splice(index + 1, 0, elementId);
    else if (direction === 'up') order.splice(index - 1, 0, elementId);

    resortItems(order);
  };

  return (
    <>
      <CheckboxButton
        labelText={t('webshop.build.openSequentially')}
        checked={checked}
        handleChange={onChange}
      />
      <Typo.Info $marginBottom="10px">
        {t('webshop.build.setOrderDescription')}
      </Typo.Info>
      {!checked ? null : !sequence || sequence.length < 2 ? (
        <Typo.Info $marginBottom="10px">{t('webshop.build.selectAtLeastTwo')}</Typo.Info>
      ) : (
        <>
          <Typo.TextTitle>{t('webshop.build.setOrderTitle')}</Typo.TextTitle>
          <List>
            {sequence.map((id, index) => {
              const courseToUse = courses.find(course => course.id === id);
              if (!courseToUse) return null;

              return (
                <ListItem key={courseToUse.id}>
                  <Typo.Text>{`${courseToUse.name} - ${courseToUse.id}`}</Typo.Text>
                  <IconAsButton
                    disabled={index === 0}
                    tooltipLabel={t('webshop.build.moveUp')}
                    iconName="ArrowUp"
                    clickHandle={() => moveItem(courseToUse.id, 'up')}
                  />
                  <IconAsButton
                    disabled={index === sequence.length - 1}
                    tooltipLabel={t('webshop.build.moveDown')}
                    iconName="ArrowDown"
                    clickHandle={() => moveItem(courseToUse.id, 'down')}
                  />
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </>
  );
};

export default EditPackageCourseSequence;
