import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/sv';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Flex } from '../../../ui';
import { selectLocalization } from '../../auth/authSlice';

dayjs.extend(quarterOfYear)

const TimePeriodSelector = ({startDate, setStartDate, endDate, setEndDate}) => {
  const { t } = useTranslation();
  const locale = useSelector(selectLocalization)

  useEffect(() => {
    !!locale && dayjs.locale(locale)
  }, [locale])

  return (
    locale && <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <Flex gap='40' justify={'center'}>
        <DatePicker
          label={t('globals.startDate')}
          value={startDate || dayjs().startOf('quarter')}
          onChange={setStartDate}
        />
        <DatePicker
          label={t('globals.endDate')}
          value={endDate || dayjs()}
          onChange={setEndDate}
        />
      </Flex>
    </LocalizationProvider>
  );
};

export default TimePeriodSelector;
