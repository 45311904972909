import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_SUCCEEDED, STATUS_LOADING, TOAST_SUCCESS, TOAST_ERROR } from '../../utils/constants';
import { enqueueSnackbar } from 'notistack'
import { notesApi, pageModuleApi } from '../../utils/urls';
import httpClient from "../../services/httpClient";
import i18next from "i18next";
// ----------------- Thunks -----------------------------

export const fetchNotes = createAsyncThunk('notes/fetchNotes', async (_, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'findAll'
  };

  const res = await httpClient.post(notesApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
})

export const saveNotes = createAsyncThunk('notes/saveNotes', async ({pageModuleId, text}, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data:{
      id:pageModuleId,
      note:{
        text,
        color:'red'
      }
    },
    method: 'note'
  };

  const res = await httpClient.post(pageModuleApi(), body, { getState, dispatch, rejectWithValue });

  return res.data;
})

export const createNote = createAsyncThunk('notes/createNote', async ({pageId, text}, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data:{
      id:pageId,
      text,
      color:'red'
    },
    method: 'create'
  };
  const res = await httpClient.post(notesApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
})
export const deleteNote = createAsyncThunk('notes/deleteNote', async ({pageId, noteId}, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data:{
      pageId,
      noteId,
    },
    method: 'removeNote'
  };
  const res = await httpClient.post(notesApi(), body, { getState, dispatch, rejectWithValue });
  return res.data;
})

// ----------------- Reducers -----------------------------

const notesAdapter = createEntityAdapter()

const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    status: 'idle',
    ids: [],
    entities: {}
  },
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(fetchNotes.pending, (state) => {
        state.status = STATUS_LOADING
      })
      .addCase(fetchNotes.rejected, (state) => {
        state.status = STATUS_FAILED
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        const data = action.payload;
        if (!data) return;
        const byPage = Array.isArray(data.by_page) ? data.by_page.flat() : [];
        const byPageModule = Array.isArray(data.by_page_module) ? data.by_page_module.flat() : [];
        // Merge courses by their `id`
        const mergedCoursesMap = new Map();

        [...byPage, ...byPageModule].forEach((course) => {
          if (!mergedCoursesMap.has(course.id)) {
            mergedCoursesMap.set(course.id, { ...course, sections: [] });
          }
          const existingCourse = mergedCoursesMap.get(course.id);

          course.sections.forEach((section) => {
            let existingSection = existingCourse.sections.find((s) => s.id === section.id);
            if (!existingSection) {
              existingSection = { ...section, pages: [] };
              existingCourse.sections.push(existingSection);
            }

            section.pages.forEach((page) => {
              let existingPage = existingSection.pages.find((p) => p.id === page.id);
              if (!existingPage) {
                existingPage = { ...page, notes: [] };
                existingSection.pages.push(existingPage);
              }

              existingPage.notes = [...(existingPage.notes || []), ...(page.notes || [])];
            });
          });
        });

        const mergedCourses = Array.from(mergedCoursesMap.values());

        notesAdapter.setAll(state, mergedCourses);
      })
      .addCase(saveNotes.pending, (state) => {
        state.status = STATUS_LOADING
      })
      .addCase(saveNotes.rejected, (state, action) => {
        state.status = STATUS_FAILED
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(createNote.rejected, (state, action) => {
        state.status = STATUS_FAILED
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR })
      })
      .addCase(saveNotes.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar(i18next.t('notes.saved'), { variant: TOAST_SUCCESS });
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar(i18next.t('notes.added'), { variant: TOAST_SUCCESS });
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar(i18next.t('notes.deleted'), { variant: TOAST_SUCCESS });
      })
  }
})

// export const { } = notesSlice.actions

export default notesSlice.reducer

// ----------------- Selectors -----------------------------


export const {
  selectAll: selectNotes,
  selectById: selectNoteById,
} = notesAdapter.getSelectors((state) => state.notes)
