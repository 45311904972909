import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Typo } from '../../../ui';
import { selectUserName } from '../../auth/authSlice';
import CoursesListCarousel from './CoursesListCarousel';

const CompletedCoursesList = ({courses}) => {
  // const { t } = useTranslation();

  const [completedCourses, setCompletedCourses] = useState([]);
  const userName = useSelector(selectUserName)

  useEffect(() => {
    
    const filteredCourses = courses.map((course)=>{
      if(course.progress === 1 && !course.canEdit) return course;
      else return null;
    }).filter(Boolean);

    setCompletedCourses(filteredCourses)

  },[courses, userName]);

  if(!completedCourses.length) return null

  return (<Flex width='100%' direction='column' align='flex-start' gap='20'>
      <Typo.ContentSmallHeader $textAlign='left'>Completed courses</Typo.ContentSmallHeader>
      <CoursesListCarousel courses={completedCourses} />
    </Flex>
  );
};

export default CompletedCoursesList;