import { useEffect, useRef, useCallback } from 'react';

const useSwipe = (onSwipeLeft, onSwipeRight, minSwipeDistance = 50) => {
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  const handleTouchStart = useCallback((e) => {
    if (e.target.closest('.swiper')) return;

    touchStartX.current = e.targetTouches[0].clientX;
    touchEndX.current = null;
  }, []);

  const handleTouchMove = useCallback((e) => {
    touchEndX.current = e.targetTouches[0].clientX;
  }, []);

  const handleTouchEnd = useCallback(() => {
    if (!touchStartX.current || !touchEndX.current) return;
    const distance = touchStartX.current - touchEndX.current;

    if (distance > minSwipeDistance) onSwipeLeft();
    if (distance < -minSwipeDistance) onSwipeRight();
  }, [onSwipeLeft, onSwipeRight, minSwipeDistance]);

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

  return null;
};

export default useSwipe;
