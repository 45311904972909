import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex, theme, IconAsButton } from '../../ui';
import { fetchCourseById, selectCourse } from '../course/courseSlice';
import { useNewViewCourseId, useNewViewPageId } from '../../hooks/readFromUrls';
import { drawerWidth } from '../../utils/constants';
import { media } from '../../utils/media';
import TocMenu from './components/TocMenu';
import CoursePage from './components/CoursePage';
import { getDarkerThemeColor, getLighterThemeColor, getUserThemeColor } from '../../ui/theme/colors';
import NotesDrawer from './components/NotesDrawer/Index';
import { selectNotesSliderToggle, setNotesSliderToggle } from '../global/globalSlice';
import NotesMobile from './components/NotesDrawer/NotesMobile';
import usePageNavigation from '../../utils/pageNavigation';
import useSwipe from './components/useSwipes';
import { selectTocMenuIsClosed, toggleTocMenuIsClosed } from './viewCourseSlice';
import NavButtons from './components/NavButtons'

const LockedSectionMessage = styled.div`
  padding: 20px;
  text-align: center;
  color: ${theme.colors.NEUTRAL_60};
`;

const ContentWrapper = styled(Flex)`
  margin-left: ${({ $isTocMenuClosed }) => ($isTocMenuClosed ? '46' : drawerWidth)}px;
  position: relative;
  height: calc(100vh - ${theme.headerSize});
  margin-right: 0;

  ${media.greaterThan('bigTablet')} {
    margin-right: ${({ $extraMargin }) => ($extraMargin ? drawerWidth : 0)}px;
  }

  ${media.lessThan('tablet')} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: calc(${theme.headerSize} + 10px);
  left: ${({$minimizedMenu})=> $minimizedMenu ? 0 : `calc( ${drawerWidth}px - 46px)`};
  z-index: 3;

  ${media.lessThan('tablet')} {
    display:none;
  }
`

const ViewCourse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courseId = useNewViewCourseId();
  const urlPageId = useNewViewPageId();
  const course = useSelector(selectCourse);
  const isNotesOpen = useSelector(selectNotesSliderToggle);
  const isTocMenuClosed = useSelector(selectTocMenuIsClosed)

  useEffect(() => {
    dispatch(fetchCourseById({ courseId })).then((res) => {
      const courseData = res.payload;
      if (!courseData?.toc || urlPageId === 'diploma') return;

      let foundPage = null;
      let sectionIsLocked = false;

      courseData.toc.forEach((section) => {
        section.pages.forEach((page) => {
          if (page.id.toString() === urlPageId) {
            foundPage = page;
            sectionIsLocked = section.isLocked;
          }
        });
      });

      if (foundPage && !sectionIsLocked) {
        return;
      }

      const availableSections = courseData.toc.filter((section) => !section.isLocked);
      const firstAvailablePage = availableSections[0]?.pages.find((page) => !page.isLocked);

      if (!availableSections.length || !firstAvailablePage) {
        navigate(`/view/course/${courseId}`, { replace: true });
        return;
      }

      const initialPage = courseData.lastViewedPageId || firstAvailablePage.id;
      navigate(`/view/course/${courseId}/${initialPage}`, { replace: true });
    });
  }, [courseId, urlPageId, dispatch, navigate]);

  const { prev, next } = usePageNavigation(course?.toc || [], urlPageId);

  const isPageLocked = useCallback((pageId) => {
    return course?.toc?.some((section) => section.isLocked && section.pages.some((page) => page.id === pageId));
  }, [course.toc]);

  const goToPage = useCallback((pageId) => {
    if (!isPageLocked(pageId)) {
      navigate(`/view/course/${courseId}/${pageId}`, { replace: true });
    }
  },[courseId, isPageLocked, navigate]);

  const handleKeyPress = useCallback((event) => {
    const { activeElement } = document;

    if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
      return;
    }

    if(event.key === 'ArrowRight' && next?.id) goToPage(next?.id)
    else if(event.key === 'ArrowLeft' && prev?.id) goToPage(prev?.id)
  }, [next, prev, goToPage])

  useEffect(()=>{
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress])

  const handleOpenNotes = () => {
    dispatch(setNotesSliderToggle(!isNotesOpen));
  };

  const themeColor = useMemo(() => getUserThemeColor(course?.theme?.color), [course?.theme?.color]);// eslint-disable-line react-hooks/exhaustive-deps
  const lightColor = useMemo(() => getLighterThemeColor(themeColor), [themeColor]);
  const darkColor = useMemo(() => getDarkerThemeColor(themeColor), [themeColor]);

  const allSectionsLocked = useMemo(() => {
    return course?.toc?.every((section) => section.isLocked);
  }, [course]);

  const handleSwipeRight = useCallback(() => {
    if(prev?.id)
      goToPage(prev?.id)
  }, [prev, goToPage]);

  const handleSwipeLeft = useCallback(() => {
    if(next?.id)
      goToPage(next?.id)
  }, [next, goToPage]);

  const isQuiz = useMemo(() => {
    return course?.settings?.quiz?.enabled && course?.settings?.quiz?.pages?.hasOwnProperty(urlPageId);
  }, [course, urlPageId]);

  useSwipe(handleSwipeLeft, handleSwipeRight);

  const toggleMenuOpen = () => {
    dispatch(toggleTocMenuIsClosed())
  }

  return (
    <>
      <ButtonWrapper $minimizedMenu={isTocMenuClosed}>
        <IconAsButton
          clickHandle={toggleMenuOpen}
          tooltipLabel={!!isTocMenuClosed ? t('coursePage.expand') : t('coursePage.minimize')}
          iconName={!!isTocMenuClosed ? 'List' : 'DoubleArrowLeft'}
        />
      </ButtonWrapper>
      <TocMenu isTocMenuClosed={isTocMenuClosed} />
      <ContentWrapper direction='column' $extraMargin={isNotesOpen} $isTocMenuClosed={isTocMenuClosed} >
        {allSectionsLocked ? (
          <LockedSectionMessage>
            <h2>{t('coursePage.allLocked')}</h2>
            <p>{t('coursePage.contactAdmin')}</p>
          </LockedSectionMessage>
        ) : (
          <>
            <CoursePage
              biggerPageSize={isTocMenuClosed}
              courseId={courseId}
              pageId={urlPageId}
              isQuiz={isQuiz}
              isNotesOpen={isNotesOpen}
              quizData={isQuiz ? course.settings.quiz.pages[urlPageId] : null}
            />
            <NotesMobile fullWidth={isTocMenuClosed} makeItOpen={isNotesOpen} setNotesDrawerOpen={handleOpenNotes} />
            <NavButtons
              prev={prev}
              next={next}
              goToPage={goToPage}
              isTocMenuClosed={isTocMenuClosed}
              isNotesOpen={isNotesOpen}
              lightColor={lightColor}
              darkColor={darkColor}
              handleOpenNotes={handleOpenNotes}
              themeColor={themeColor}
            />
          </>
        )}
      </ContentWrapper>
      <NotesDrawer makeItOpen={isNotesOpen} setNotesDrawerOpen={handleOpenNotes} />
    </>
  );
};

export default ViewCourse;
