import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Typo } from '../../../ui';
import { selectUserName } from '../../auth/authSlice';
import CoursesListCarousel from './CoursesListCarousel';

const OngoingCoursesList = ({courses}) => {
  // const { t } = useTranslation();

  const userName = useSelector(selectUserName);
  const [ongoingCourses, setOngoignCourses] = useState([])

  useEffect(() => {
    
    const filteredCourses = courses.map((course)=>{
      if(!course.canEdit && course.progress < 1) return course;
      else return null;
    }).filter(Boolean);

    setOngoignCourses(filteredCourses)

  },[courses, userName])

  if(!ongoingCourses.length) return null

  return (<Flex width='100%' direction='column' align='flex-start' gap='20'>
      <Typo.ContentSmallHeader $textAlign='left'>Ongoing courses</Typo.ContentSmallHeader>
      <CoursesListCarousel courses={ongoingCourses} />
    </Flex>
  );
};

export default OngoingCoursesList;