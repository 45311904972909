import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListCourses from '../../components/ListCourses';
import {
  fetchDashboard,
  selectFilterOptions,
  selectMyCoursesFiltered,
  setDashboardFilter,
  selectFilter,
  selectDashboardStatus
} from './dashboardSlice';
import styled from 'styled-components';
import { Filter, Page, Toggler, Button, Loading } from '../../ui';
import { selectHasRequiredLevel, selectIsIncarnated } from '../auth/authSlice';
import { CONTENT_STANDARD, media } from '../../utils/media';
import DashboardCourseCard from '../../ui/CourseCard/DashboardCourseCard';
import { STATUS_LOADING } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../ui/Page/PageHeader';
import PageControls from '../../ui/Page/PageControls';

const FilterGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap:10px;

  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:10px;
    flex-wrap: wrap;
  }
`;

const DashboardContentOld = ({openModal}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const myCourses = useSelector(selectMyCoursesFiltered);
  const dashboardStatus = useSelector(selectDashboardStatus);
  const [sortMode, setSortMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const isIncarnated = useSelector(selectIsIncarnated);
  const filters = useSelector(selectFilterOptions);
  const selectedFilter = useSelector(selectFilter);

  const isOwner = useSelector((state) => selectHasRequiredLevel(state, 'owner'))

  const handleSortMode = useCallback((checked) => {
    setSortMode(checked);
  }, []);

  const handleSearch = useCallback((value) => {
    setSearchQuery(value);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
  }, []);

  const handleOptions = useCallback((value) => {
    dispatch(setDashboardFilter(+value));
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchDashboard({ sortBy: sortMode ? 'created' : 'lastVisit', filters: [{ mode: 'name', value: searchQuery }] }));
  }, [dispatch, sortMode, searchQuery, isIncarnated])


  return (
    <Page size={CONTENT_STANDARD} staticElements={
      <PageHeader>
        <PageControls
          onClearSearch={clearSearch}
          onSearch={handleSearch}
          placeholder={t('dashboard.searchPlaceholder')}
          disableClear={!searchQuery}
          openModelButton={
            isOwner && <Button icon='Edit' onClick={openModal}>{t('course.createCourse')}</Button>
          }
        >
          <FilterGroup>
            <Filter info={filters} handleFilter={handleOptions} selected={selectedFilter} />
            <Toggler leftText={t('dashboard.lastVisited')} rightText={t('dashboard.lastCreated')} onClick={handleSortMode} />
          </FilterGroup>
        </PageControls>
      </PageHeader>
    }>
      {dashboardStatus === STATUS_LOADING ?
        <Loading /> :
        <ListCourses showProgress={true} showButtons={false} module={'dashboard'} items={myCourses} CardComponent={DashboardCourseCard} />}
        {/* <CreateCourseModel open={isModalOpen} onClose={closeModal} /> */}
    </Page>
  );
};

export default DashboardContentOld;
