import React from 'react'
import Buttons from '../../../ui/Buttons';
import styled from 'styled-components';
import { IconAsButton } from '../../../ui';
import { coursePageNavigation, drawerWidth } from '../../../utils/constants';
import { media } from '../../../utils/media';
import { getDarkerThemeColor } from '../../../ui/theme/colors';
import { useTranslation } from "react-i18next";

const IconAsButtonStyled = styled(IconAsButton)`
  position: absolute !important;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index: 999;
`;

const NavButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  height: ${coursePageNavigation}px;
  justify-content: space-between;
  background-color: ${({ $backgroundColor }) => $backgroundColor} !important;
  width: calc(100% - ${(props) => (props.$isTocMenuClosed ? '46' : drawerWidth)}px);

  ${media.greaterThan('bigTablet')} {
    width: calc(100% - ${(props) => (props.$compactSize ? props.$isTocMenuClosed ? drawerWidth + 46  : drawerWidth * 2 : props.$isTocMenuClosed ? '46' : drawerWidth )}px);
  }

  ${media.lessThan('tablet')} {
    width: 100%;
  }
`;

const NavButtons = React.memo(({ prev, next, goToPage, isTocMenuClosed, isNotesOpen, lightColor, darkColor, handleOpenNotes, themeColor }) => {
  const { t } = useTranslation();

  return (
    <NavButtonWrapper $isTocMenuClosed={isTocMenuClosed} $compactSize={isNotesOpen} $backgroundColor={lightColor}>
      <Buttons.NavButton
        disabled={!prev || !prev.id}
        onClick={() => goToPage(prev?.id)}
        $align={'left'}
        label={prev?.name || ''}
        startIcon={'ArrowLeft'}
        $with={'calc(50% - 22px)'}
        $hoverColor={darkColor}
        $bgcolor={lightColor}
      />
      <IconAsButtonStyled
        iconName={'EditNote'}
        tooltipLabel={t('coursePage.notes.yourNotes')}
        color={getDarkerThemeColor(themeColor)}
        clickHandle={handleOpenNotes}
      />
      <Buttons.NavButton
        disabled={!next || !next.id}
        onClick={() => goToPage(next?.id)}
        $align={'right'}
        label={next?.name || ''}
        endIcon={'ArrowRight'}
        $with={'calc(50% - 22px)'}
        $hoverColor={darkColor}
        $bgcolor={lightColor}
      />
    </NavButtonWrapper>
  );
});

export default NavButtons;
