import React from 'react';
import styled from 'styled-components'
import { theme } from '..';

const RibbonStyled = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  z-index:2;

  --f: .5em; /* control the folded part*/
  --r: .8em; /* control the ribbon shape */
  
  position: absolute;
  top: 20px;
  left: calc(-1*var(--f));
  padding-inline: 1.5em;
  line-height: 1.8;
  background: ${theme.colors.SECONDARY};
  border-bottom: var(--f) solid #0005;
  border-right: var(--r) solid #0000;
  clip-path: 
    polygon(calc(100% - var(--r)) 0,0 0,0 calc(100% - var(--f)),var(--f) 100%,
      var(--f) calc(100% - var(--f)),calc(100% - var(--r)) calc(100% - var(--f)),
      100% calc(50% - var(--f)/2));

  &:before {
    content: attr(data-label-word);
  }
`

const Ribbon = ({word}) => {

  return (
   <RibbonStyled data-label-word={word}/>
  );
};

export default Ribbon;
