import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ListTable,  Loading } from '../../../ui';
import { selectAnalytics } from '../../statistics/statisticsSlice';
import { currencyFormatter, fractionsFormat } from '../../../helpers/formatNumbers';
import { selectCourses } from '../../webshop/coursesSlice';

const SalesDataTable = ({openAnalyticDetails, sales, invitations }) => {
  const { t } = useTranslation();
  const analytics = useSelector(selectAnalytics);
  const courses = useSelector(selectCourses)
  const [dataSales, setDataSales] = useState();

  useEffect(() => {

    const populateSalesDataWithGA = (
      id,
      slug,
      name,
      salesCount,
      salesAmount,
      invites,
      invitesAccepted
    ) => {

      const gaData = {
        id,
        slug,
        name,
        pageViews: 0,
        time: { minutes: 0, seconds: 0 },
        conversionRate: 0,
        quatity: salesCount,
        amount: salesAmount,
        invites,
        invitesAccepted
      };

      let viewsCount = 0;
      let timeCount = 0;
      let count = 0;

      for (let i = 0; i < analytics.length; i++) {
        if (analytics[i] && analytics[i].dimensions[0].includes(slug)) {
          count++;
          viewsCount += +Number(analytics[i].metrics[0]);
          timeCount += +Number(analytics[i].metrics[1]);
        }
      }

      gaData.pageViews = viewsCount;
      const averageTime = count > 10 ? timeCount / viewsCount : timeCount
      const minutes = Math.floor(averageTime / 60);
      const seconds = Math.floor(averageTime - minutes * 60);
      gaData.time = { minutes, seconds };
      gaData.conversionRate = viewsCount > 0 && salesCount > 0 ? ((salesCount / viewsCount) * 100) : 0;

      return gaData;
    };

    const newData = courses.map((course) => {
      const courseSales = sales?.salesPerObject?.find(element => +element.id === course.id);
      const courseInvites = invitations?.invPerCourse?.find(element => element.id === course.id);

      if (course.type === 1 || course.type === 2) {
        return populateSalesDataWithGA(
          course.id,
          course.slug,
          course.name,
          courseSales?.salesCount || 0,
          courseSales?.salesAmount || 0,
          courseInvites?.count || 0,
          courseInvites?.acceptedCount || 0
        );
      } else return null;
    })
    newData.length && setDataSales(newData);
  }, [sales, analytics, courses, invitations])

  const tableInfoSales = useMemo(() => ([
    { label: t('globals.course'), data: (course) => course.name },
    { label: t('insights.quantity'), data: (course) => course.quatity },
    {
      label: t('insights.amount'),
      data: (course) => course.amount && currencyFormatter(+course.amount, { symbol: sales.storeCurrency, significantDigits: 2 })
    },
    { label: t('insights.invitesSent'), data: (course) => course.invites },
    { label: t('insights.invitesAccepted'), data: (course) => course.invitesAccepted },
    {
      label: t('insights.pageViews'),
      data: (course) => course.pageViews
    },
    { label: t('insights.timeSpent'), data: (course) => `${course.time.minutes}.${course.time.seconds}min` },
    { label: t('insights.conversion'), data: (course) => `${fractionsFormat(course.conversionRate)}%` },

  ]), [sales.storeCurrency, t]);

  return (
    !dataSales ? <Loading /> : !!dataSales?.length &&
      <ListTable
        tableInfo={tableInfoSales}
        data={dataSales}
        tableName={'sales'}
        onRowClick={openAnalyticDetails}
      />
  );
};

export default SalesDataTable;
