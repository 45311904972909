import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'

import { Typo, Icon, Button, Textinput, Flex, theme } from '../../../../ui';
import { media } from '../../../../utils/media';
import { selectIsAdmin, selectIsIncarnated } from '../../../auth/authSlice';
import FileUploader from '../../../media/uploaders/FileUploader';
import { updateCourse } from '../../courseSlice';
import debounce from 'lodash.debounce';
import DownloadableFile from '../../../../ui/DownloadableFile';
import { emailDiploma } from '../../../diploma/diplomaSlice';

const ButtonsWrapper = styled.div`
  display: flex;
  width:100%;
  justify-content: flex-end;
  
  button {
    margin-left:0.5rem;
  }
`
const DiplomaFile = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 70%;
  width:100%;
  ${media.lessThan('laptop')} {
    flex-direction:column;
    gap:10px;
  }
`

const FlexContainer = styled.div` 
  display: flex; 
  align-items: center;
`

const CourseDiplomaEdit = ({ course }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [diploma, setDiploma] = useState(null);
  const [customEmail, setCustomEmail] = useState(null)
  const isAmin = useSelector(selectIsAdmin)
  const isIncarnated = useSelector(selectIsIncarnated);

  const emailRef = useRef(null);

  const createTemplateInfo = {url:"https://s3.eu-west-1.amazonaws.com/s3.s.coursio.com/ENG-create-diploma-instructions-2024.pdf", name:'create-diploma-instructions-2024'}

  useEffect(() => {
    if (course?.settings?.diploma?.hasOwnProperty('fileId') && course.settings.diploma.fileId) {
      setDiploma(course.settings?.diploma)
    } else {
      setDiploma(null);
    }
  }, [course]);

  const onSaveDiploma = useCallback((diploma) => {
    const { fileId, passPercentage } = diploma;

    const courseData = {
      description:course.description,
      categories:course.categories,
      id: course.id,
      settings: {
        ...course.settings,
        diploma: {
          fileId,
          passPercentage
        }
      }
    };

    dispatch(updateCourse({ courseData }));
  }, [course, dispatch]);

  const onRemoveDiploma = useCallback(() => {
    const courseData = {
      description:course.description,
      categories:course.categories,
      id: course.id,
      settings: {
        ...course.settings,
        diploma: {
          fileId: null
        }
      }
    };

    dispatch(updateCourse({ courseData }));
  }, [course, dispatch]);

  const onSavePassPercentage = useCallback((e) => {
    const courseData = {
      description:course.description,
      categories:course.categories,
      id: course.id,
      settings: {
        ...course.settings,
        diploma: {
          ...course.settings.diploma,
          passPercentage: e.target.value
        }
      }
    };

    dispatch(updateCourse({ courseData }))
  }, [course, dispatch])

  const sendTestDiploma = useCallback(() => {
    dispatch(emailDiploma({ courseId: course.id, recipientEmail: customEmail }));
  }, [dispatch, course, customEmail])

  const saveDiploma = useCallback((file) => {
    file && onSaveDiploma({ fileId: file.id, passPercentage: 80 })
  }, [onSaveDiploma])

  const handleEmailChange = useCallback(debounce(() => {
    const { current: { value } } = emailRef;

    if (value.length < 1)
      setCustomEmail(null);
    else setCustomEmail(value);

  }, 1000), [emailRef, setCustomEmail]);

  return (
    <Flex width='100%' direction='column' align='flex-start' gap='30'>
      <Flex direction='column' align='flex-start' >
        <Typo.ContentBigHeader $textAlign='left'>{t('globals.diploma')}</Typo.ContentBigHeader>
        <Typo.SmallText $textAlign='left'> {t('settings.UploadDiplomaHelper')} </Typo.SmallText>
        <DownloadableFile.AsText label={t('settings.downloadInstructions')} file={createTemplateInfo}/>
      </Flex>

      {diploma?.fileId ?
        <Flex width='100%' direction='column' align='flex-start' >
          <DiplomaFile>
            <FlexContainer>
              <Icon.Certificate />
              <Typo.Text>{t('globals.diploma')}</Typo.Text>
            </FlexContainer>
            <div>
              <Typo.Text>{t('course.percentageForDiploma')}</Typo.Text>
              <Textinput
                defaultValue={diploma.passPercentage}
                onBlur={onSavePassPercentage}
              />
            </div>
          </DiplomaFile>
          {(isAmin || isIncarnated) && <Textinput
            margin='dense'
            id='email'
            label={'send it to this email instead (for admins only)'}
            type='text'
            fullWidth
            inputRef={emailRef}
            onChange={handleEmailChange}
          />}
          <ButtonsWrapper>
            <Button onClick={onRemoveDiploma}>{t('course.removeDiploma')}</Button>
            <Button onClick={sendTestDiploma}>{t('course.previewDiploma')}</Button>
          </ButtonsWrapper>
        </Flex>
        : <FileUploader 
            id={'diplomaTemplate'} 
            saveAction={saveDiploma} 
            fileType='other' 
            label={t('settings.uploadTemplate')}
            iconName='Upload'
            variant='customized' 
            bgcolor={theme.colors.COURSIO_NEW_EXTRA} 
          />
      }
    </Flex>
  );
};

export default CourseDiplomaEdit;
