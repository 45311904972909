import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/sv';
import {
  fetchInvitations,
  fetchSales,
  selectInvitations,
  selectSales
} from '../../statistics/statisticsSlice';
import StatisticsCards from '../../insights/components/StatisticsCards';

const StatisticsWidget = () => {
  const dispatch = useDispatch();
  const sales = useSelector(selectSales);
  const invitations = useSelector(selectInvitations);

  useEffect(() => {
    const startDate = dayjs().startOf('year');
    const endDate = dayjs();

    if (endDate && startDate) {
      const dates = { startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] }
      dispatch(fetchSales(dates));
      dispatch(fetchInvitations(dates));
    }
  }, [dispatch])

  return (
    <StatisticsCards sales={sales} invitations={invitations} />
  );
};

export default StatisticsWidget;
