import React, { useEffect, useState } from 'react';
import { Button, Flex, Icon, Typo } from '../../../../ui';
import ResultsScreen from './ResultsScreen';
import { useTranslation } from 'react-i18next';

const StartScreen = ({ quizSettings, pageInfo, handleStartQuiz, hasPrevAnswers, lastQuizData, canShowResults, themeColor }) => {
  const { t } = useTranslation();
  const [showResults, setShowResults] = useState(false)

  useEffect(() => {
    setShowResults(!quizSettings.repeatable && hasPrevAnswers)
  }, [hasPrevAnswers, quizSettings.repeatable])

  const handleShowResults = () =>{
    setShowResults(true)
  }

  return (
    showResults ?
      <ResultsScreen
        pageInfo={pageInfo}
        canShowResults={canShowResults}
        lastQuiz={lastQuizData}
        themeColor={themeColor}
        handleStartQuiz={quizSettings.repeatable && handleStartQuiz}
        quizSettings={quizSettings} />
      : <>
        <Icon.Questions size='xxl' color={themeColor}/>
        <Typo.BigText>{quizSettings?.welcomeText || t('quiz.welcome')}</Typo.BigText>
        <Typo.MediumText>{quizSettings?.descriptionText}</Typo.MediumText>
        <Flex gap='10' justify='center' width='100%'>
          {(quizSettings.repeatable || !hasPrevAnswers) && <Button $variant='customized' $bgcolor={themeColor} onClick={handleStartQuiz}>{t('quiz.start')}</Button>}
          {hasPrevAnswers && <Button $variant='customized' $bgcolor={themeColor} onClick={handleShowResults}>{t('quiz.previousResult')}</Button>}
        </Flex>
      </>
  )
};

export default StartScreen;
