import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {fetchDashboard,selectMyCoursesFiltered, selectDashboardStatus } from './dashboardSlice';
import { Page,  Loading } from '../../ui';
import { selectHasRequiredLevel, selectIsIncarnated } from '../auth/authSlice';
import { CONTENT_STANDARD, media } from '../../utils/media';
import CreateCourseModel from '../course/components/CreateCourseModel';
import { STATUS_LOADING } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import PageControls from '../../ui/Page/PageControls';
import DashboardContentOld from './DashboardContentOld';
import { selectFeatures, selectFetchStatus } from '../settings/settingsSlice';
import CreateYourFirstCourse from './components/CreateYourFirstCourse';
import OngoingCoursesList from './components/OngoingCoursesList';
import CompletedCoursesList from './components/CompletedCoursesList';
import DraftCoursesList from './components/DraftCoursesList';
import WelcomeMessage from './components/WelcomeMessage';
import { selectCartItemsCount } from '../webshop/cartSlice';
import SearchResults from './components/SearchResult';
import StatisticsWidget from './components/StatisticsWidget';
import EditableCoursesList from './components/EditableCoursesList';

const HeaderSearch = styled.div`
  position: absolute;
  top: 8px;
  right: ${({$hasExtraMargem})=> !$hasExtraMargem ? '8px' : '64px'};
  z-index: 1200;
  ${media.greaterThan('laptop')} {
    right: 20px;
  }
`

const Dashboard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const features = useSelector(selectFeatures);
  const settingsStatus = useSelector(selectFetchStatus);

  const myCourses = useSelector(selectMyCoursesFiltered);
  const dashboardStatus = useSelector(selectDashboardStatus);
  // const [sortMode, setSortMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isIncarnated = useSelector(selectIsIncarnated);
  const cartItemsCount = useSelector(selectCartItemsCount)

  const isOwner = useSelector((state) => selectHasRequiredLevel(state, 'owner'));

  useEffect(() => {
    // dispatch(fetchDashboard({ sortBy: sortMode ? 'created' : 'lastVisit', filters: [{ mode: 'name', value: searchQuery }] }));
    dispatch(fetchDashboard({ sortBy:'lastVisit', filters: [{ mode: 'name', value: searchQuery }] }));
  }, [dispatch, searchQuery, isIncarnated]); //sortMode

  const openModal = () =>
    setIsModalOpen(true);
  
  const closeModal = () =>
    setIsModalOpen(false);
  
  const clearSearch = useCallback(() => {
    setSearchQuery('')
  }, []);

  const handleSearch = useCallback((value) => {
    setSearchQuery(value)
  }, []);
  
  return (<>
    {settingsStatus === STATUS_LOADING || settingsStatus === 'idle'
      ? <Loading /> 
      : features.newDashboard 
        ? <Page size={CONTENT_STANDARD} gap='60'>
            <HeaderSearch $hasExtraMargem={!!cartItemsCount}>
              <PageControls
                onClearSearch={clearSearch}
                onSearch={handleSearch}
                placeholder={t('dashboard.searchPlaceholder')}
                disableClear={!searchQuery}
              />
            </HeaderSearch>
            <WelcomeMessage />
            {isOwner && <StatisticsWidget />}
            {dashboardStatus === STATUS_LOADING ? <Loading /> :
            !searchQuery.length ? <>
              {isOwner && !myCourses.length && <CreateYourFirstCourse openCreateCourseModal />}
              {isOwner && <DraftCoursesList  courses={myCourses} openModal={openModal} searchQuery={searchQuery} handleSearch={handleSearch} clearSearch={clearSearch}/>}
              {!!myCourses.length && <EditableCoursesList courses={myCourses}/>}
              {!!myCourses.length && <OngoingCoursesList courses={myCourses} searchQuery={searchQuery} handleSearch={handleSearch} clearSearch={clearSearch}/>}
              {!!myCourses.length && <CompletedCoursesList courses={myCourses} />}
            </> : <SearchResults courses={myCourses} />}
          </Page>
        :  <DashboardContentOld openModal={openModal} />}
    <CreateCourseModel open={isModalOpen} onClose={closeModal} /> 
  </>
  );
};

export default Dashboard;
