import Reflux from 'reflux';

const CourseActions = Reflux.createActions([
  'setCategory',
  'addTag',
  'addTagComplete',
  'removeTag',
  'removeTagComplete',
  'increaseModuleSize',
  'increaseModuleSizeComplete',
  'sanitizePage',
  'filter',
  'setFilter',
  'search',
  'resetFilter',
  'setSort',
  'resetSort',
  'updatePageModule',
  'updatePageModuleComplete',
  'findBySlug',
  'findBySlugComplete',
  'getPageById',
  'getPageByIdComplete',
  'getPageBySlug',
  'getPageBySlugComplete',
  'getUserProgress',
  'saveSettings',
  'updateDiploma',
  'removeDiploma',
  'retrieveDiploma',
  'downloadDiploma',
  'findById',
  'findByIdComplete',
  'findBuildable',
  'findBuildableComplete',
  'findByUserId',
  'findByUserIdComplete',
  'findByGroupId',
  'moveModule',
  'moveModuleToRow',
  'moveModuleToNewRow',
  'moveModuleToNewRowComplete',
  'cancelNewRow',
  'deletePageRow',
  'createSectionComplete',
  'deletePageRowComplete',
  'moveModuleAfterRow',
  'addToTop',
  'deleteModule',
  'deleteModuleComplete',
  'getFile',
  'update',
  'updateComplete',
  'createComplete',
  'genericError',
  'addSection',
  'addQuizSectionComplete',
  'addQuizPageComplete',
  'addSectionComplete',
  'updateQuizSettings',
  'createPageComplete',
  'updatePageRow',
  'updatePageRowComplete',
  'updatePage',
  'updatePageComplete',
  'updateSection',
  'updateSectionComplete',
  'deleteSection',
  'deleteSectionComplete',
  'deleteQuiz',
  'deleteQuizComplete',
  'deletePage',
  'deletePageComplete',
  'genericError',
  'removeFromSelection',
  'duplicatePage',
  'duplicatePageComplete',
  'duplicateSection',
  'duplicateSectionComplete',
  'updateTask',
  'updateTaskComplete',
  'setPageModuleFile',
  'updateTaskCaption',
  {'answerTask': { asyncResult: true }},
  'answerTaskComplete',
  'note',
  'noteComplete',
  'toggleNotes',
  'findNotes',
  'resetSelection',
  'openToc',
  'closeToc',
  'toggleToc',
  'openLayoutDesign',
  'closeLayoutDesign',
  'toggleLayoutDesign',
  'markPageViewed',
  'markPageViewedComplete',
  'addPageModule',
  'addPageModuleComplete',
  'moveSection',
  'movePage',
  'movePageComplete',
  'addPage',
  'addPageComplete',
  'reorderSections',
  'reorderSectionsComplete',
  'reorderPages',
  'reorderPagesComplete',
  'setPageModuleMode',
  'pastePage',
  'pasteSection',
  'getToc',
  'getTocComplete',
  'getGuestToc',
  'getGuestTocComplete',
  'refetchToc',
  'updateDripContent'
]);

export default CourseActions;
