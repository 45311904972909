import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typo, Page, Button, Flex } from '../../ui';
import { fetchNotes, selectNotes } from './notesSlice';
import styled from 'styled-components';
import { CONTENT_SMALL } from '../../utils/media';
import { selectIsIncarnated } from '../auth/authSlice';
import { selectFeatures } from '../settings/settingsSlice';
import { useTranslation } from 'react-i18next';
import NotesOverview from './components/NotesOverview';
import { saveAllNotesToDocx } from '../../utils/docxNotesGenerator';

const CourseBlock = styled.div`
  width: 100%;
  margin: 1rem 0;
  &:not(:first-child) {
    margin: 3rem 0;
  }
`;

const Notes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notesData = useSelector(selectNotes);
  const isIncarnated = useSelector(selectIsIncarnated);
  const features = useSelector(selectFeatures)

  useEffect(() => {
    dispatch(fetchNotes());
  }, [dispatch, isIncarnated]);

  const courses = useMemo(() => {
    if (!notesData || notesData.length === 0) return [];

    return notesData.map(course => ({
      ...course,
      sections: Array.isArray(course.sections) ? course.sections : [],
    }));
  }, [notesData]);

  return (
    <Page size={CONTENT_SMALL}>
      {courses.length ? (
        <>
          <Flex alignSelf='end'>
            <Button icon={'Save'} onClick={() => saveAllNotesToDocx(courses)}>{t('notes.saveAll')}</Button>
          </Flex>
          {courses.map((course) => (
            <CourseBlock key={course.id}>
              <Typo.ContentBigHeader $textAlign='left'>{course.name}</Typo.ContentBigHeader>
              <NotesOverview notes={course.sections} course={course} features={features} />
            </CourseBlock>
          ))}
        </>
      ) : (
        <Typo.ContentSmallHeader>{t('notes.emptyStateMsg')}</Typo.ContentSmallHeader>
      )}
    </Page>
  );
};

export default Notes;
